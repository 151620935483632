﻿@import "_variables.scss";


/****************************/
/***** PRODUCT CONTENT *****/
/**************************/
/**** PPRODUCT LINE & SEARCH LISTING ****/
.product-search-container {
    .product-search {
        overflow: auto;

        .advanced-product-search-widget {
            .pqs-app {
                .pqs-pop {
                    color: #79858f;
                    font-family: "Open Sans",sans-serif;
                    font-size: 14.58px;
                    font-weight: 700;

                    label {
                        color: $clarke-black;
                        font-weight: normal;
                    }
                }

                .pqs-type, .pqs-emissions {
                    label {
                        width: 100%;

                        select {
                            max-width: 400px;
                            max-height: 30px;
                            padding: 0.25rem 1.5rem;
                        }
                    }
                }

                .pqs-sliders {
                    .slider-container {
                        padding: 16px 0;
                        margin: 10px 0;

                        .slider-input {
                            width: 75%;

                            .slider {
                                .slider-handle {
                                    input[type="text"] {
                                        background-color: $clarke-red;
                                        color: $clarke-white;
                                    }

                                    &.sh-right {
                                        top: 13px;
                                        height: 26px;
                                    }
                                }

                                .slider-fill {
                                    background-color: $clarke-red;
                                }
                            }
                        }
                    }
                }

                .pqs-search-button.go-by-form {
                    text-align: right;
                    padding-top: 25px;
                }
            }

            .pqs-divider {
                width: 100%;
                padding: 10px 0 0 0;
                border-bottom: 1px solid $clarke-black;
                margin: 0 auto 10px auto;
            }

            .pqs-kwmn {
                padding-top: 10px;
                /*border-bottom:2px solid $clarke-gray-45;*/
                /*margin-bottom:10px;*/
                .pqs-pop {
                    color: #79858f;
                    font-family: "Open Sans",sans-serif;
                    font-size: 14.58px;
                    font-weight: 700;

                    label {
                        color: $clarke-black;
                        font-weight: normal;
                    }
                }

                .pqs-search-button.go-by-text {
                    padding-top: 17px;
                }
            }

            .pqs-disclaimer {
                width: 100%;
                padding: 3px 0 0 0;

                p {
                    font-size: 13px;
                }
            }
        }

        .slider-container {
        }
    }

    .search-features {
        overflow: auto;
        margin-bottom: 30px;
        margin-top: 30px;

        .number-results {
            float: left;
        }

        .results-sort-by {
            float: right;

            li {
                display: inline-block;
                font-family: $font-stack-open-sans;
                font-size: 13px;
                letter-spacing: -0.3px;
                margin-right: 15px;

                i {
                    font-size: 17px;
                }

                a {
                    span i {
                        font-size: 13px;
                    }
                }
            }

            .sort-by {
                margin-right: 10px;
            }

            a.data-view-btn, a.sort-ctrl {
                color: $clarke-dark-gray;

                &.active {
                    color: $clarke-red;
                }
            }
        }
    }

    .product-search-results {
        overflow: auto;
        /*.xxlarge-3 {
            width: 23%;
        }*/
        .product-result {
            /*margin-left: 15px;
            margin-right: 15px;*/
            margin-bottom: 20px;
            padding-left: 10px;
            padding-right: 10px;

            &:first-child {
                padding-left: 0;
            }

            .product-result-info {
                background-color: $clarke-gray-05;
                padding: 30px;
                min-height: 335px;
                max-height: 335px;

                .title {
                    font-family: $font-stack-open-sans;
                    font-size: 17px;
                    font-weight: bold;
                    letter-spacing: -0.2px;
                    color: #ee3425;
                    margin-bottom: 16px;
                }

                h3 {
                    margin-bottom: 8px;
                }

                .description {
                    p, ul li {
                        font-family: $font-stack-open-sans;
                        font-size: 15px;
                        letter-spacing: -0.2px;
                        color: $clarke-dark-gray;
                    }

                    p {
                        margin-bottom: 17px;
                    }

                    ul {
                        margin-bottom: 27px;
                    }

                    img {
                        float: right;
                        border: none;
                    }
                }
            }
        }
    }

    select {
        height: 30px;
        font-size: 13px;
        background-image: none;
    }

    .slider {
        height: 1px;
        background-color: $clarke-black;

        .slider-handle {
            background-color: $clarke-red;
        }
    }

    [type=text] {
        height: 30px;
        font-size: 13px;
    }
}

.product-line {
    letter-spacing: -0.5px;
    color: $clarke-white;
    background-color: $clarke-red;
    padding: 12px 26px 9px 18px;
    margin-bottom: 30px;

    h3 {
        font-weight: bold;
        margin-bottom: 0;
        text-transform: uppercase;
        color: $clarke-white;
    }
}

/***** Product List Widget ******/
    .product-list-container {
        min-height: 200px;
    }

/**** PPRODUCT DETAILS PAGE ****/
.content-container .product-details {
    section {
        &.prd-section {
            z-index: 1;
        }

        margin-bottom: 40px;

        .ps-heading {
            h4 {
                font: {
                    family: $font-stack-open-sans;
                    size: 25.00px;
                    weight: 700;
                }

                color: $clarke-dark-gray;
                text-transform: none;
                margin: 0 0 20px 0;
                padding: 0;
            }
        }

        .ps-heading.ps-split {

            .psh-left {
                text-align: left;
            }

            .psh-left.columns {
                padding: {
                    left: 0;
                }
            }

            .psh-right {
                text-align: right;
            }

            .psh-right.columns {
                padding: {
                    right: 0;
                }
            }
        }

        .ps-heading.ps-full {
            .psh-full {
            }

            .psh-full.columns {
                padding: {
                    left: 0;
                    right: 0;
                }
            }
        }

        .ps-footer {
            .psf-full {
                text-align: right;
            }

            .psf-full.columns {
                padding: {
                    left: 0;
                    right: 0;
                }
            }
        }

        .ps-content {
            .data-grid {
                .data-row {
                }
            }

            .data-grid.columns {
                padding: {
                    left: 0;
                    right: 0;
                }
            }

            .spec-image {
                overflow: hidden;

                .si-image {
                    text-align: center;

                    img {
                        width: 100%;
                        max-width: 200px;
                        margin-bottom: 20px;
                    }
                }

                .si-caption {
                    h5 {
                        font-size: 16.67px;
                        font-weight: 600;
                        line-height: normal;
                        text-transform: none;
                    }
                }
            }

            .power-curve-chart {
                text-align: center;

                img {
                    width: 100%;
                }
            }

            .power-curve-info {
                .pci-data {
                    p {
                        font-size: 12.50px;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                    }
                }

                .pci-disclaimer {
                    p {
                        font-size: 8.33px;
                        font-weight: 400;
                        line-height: normal;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }

    section.product-highlight {
        h3 {
            display: inline-block;
            background-color: $clarke-red;
            padding: 5px 20px;

            font: {
                family: $font-stack-open-sans;
                size: 25.00px;
                weight: 700;
            }

            color: $clarke-white;
            text-transform: none;
        }
    }

    section.product-page-tabs {
        a {
            margin: {
                right: 7px;
            }
        }
    }

    section.product-specs {
    }
    //Apparently we're no longer showing a power curve chart image on the product detail page, instead we're showing a featured image...
    section.product-power-curve {
        text-align: center; //Per Mathes 11/21/2017, please center the product image.
        .featured-product-image {
            img {
                float: none;
                margin: 0 auto;
                width: 100%;
                //This is making the ONE image for initial launch "not look huge" [-_-]; adjust or remove for higher resolution or more wide than tall images.
                max-width: 350px;
            }
        }
    }

    section.product-documents {
    }

    section.product-images {
        .orbit {
            ul.orbit-container {
                min-height: 400px;

                li {
                    min-height: 400px;
                }
            }
        }
    }

    section.product-parts-options {
    }

    section.product-resources {
    }

    section.product-emissions {
    }
}


/***Product Details I & O Sheet***/
div#io {
    tbody tr:nth-child(2n) {
        border-bottom: 0;
        background-color: #fff;
    }

    img {
        float: none;
    }
}


@media screen and (min-width:1025px) and (max-width:1390px) {
    .product-search-container .product-search .advanced-product-search-widget .pqs-app .pqs-sliders .slider-container .slider-input {width:66%;}
}


@media screen and (min-width:1025px) {
    .content-container .product-details {
        .ps-content {
            .si-image.columns {
                padding: 0;
            }

            .si-caption.columns {
                padding: 0;
            }

            .orbit.product-image-gallery {
                .orbit-container {
                }
            }
        }
    }    
}
@media screen and (max-width:1024px) {
    .product-search-container .product-search .advanced-product-search-widget .pqs-kwmn .pqs-search-button.go-by-text {
        padding-top: 0;
    }
}
@media screen and (min-width:586px) and (max-width:810px) {
    .product-search-container .advanced-product-search-widget .pqs-app .pqs-search-button.go-by-form.columns {padding-left:0;padding-right:0;}
}
@media screen and (min-width:640px) and (max-width:1024px) {
    .content-container .product-details {
        .ps-content {
            .spec-image {
                text-align: right;

                .si-image.columns {
                    padding-right: 0;
                    float: right;
                }

                .si-caption.columns {
                    padding-left: 0;
                    float: left;
                }

                .si-caption {
                    padding-top: 30px;
                }
            }

            .power-curve-info {
                .pci-data.columns {
                    padding-left: 0;
                }

                .pci-disclaimer.columns {
                    padding-right: 0;
                }
            }
        }
    }
}
@media screen and (min-width:520px) and (max-width:666px) {
    .product-search-container .product-search .advanced-product-search-widget .pqs-app .pqs-sliders .slider-container .slider-input {width:70%;}
}

@media screen and (max-width:639px) {
    .content-container .product-details {
        .ps-content {
            .spec-image {
                text-align: right;

                .si-image.columns {
                    padding-right: 0;
                    float: right;
                }

                .si-caption.columns {
                    padding-left: 0;
                    float: left;
                }

                .si-caption {
                    padding-top: 10px;
                }
            }

            .power-curve-info {
                .pci-data.columns {
                    padding-left: 0;
                }

                .pci-disclaimer.columns {
                    padding-right: 0;
                }
            }
        }
    }
    .product-search-container .product-search .advanced-product-search-widget .pqs-app .pqs-search-button.go-by-form { text-align:left; }
}
@media screen and (max-width:519px) {
    .product-search-container .product-search .advanced-product-search-widget .pqs-app .pqs-sliders .slider-container .slider-input {width:51%;}
}

@media screen and (min-width: 0px) and (max-width: 639px) {
    .mobile-only {
        display: block;
    }
    /* show it on small screens */
}

@media screen and (min-width: 640px) {
    .mobile-only {
        display: none;
    }
    /* hide it elsewhere */
}

/*~^~.~^~.~^~*/

