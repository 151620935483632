﻿@import "gallery-variables";

@mixin gallery( $item-count ) {
	$total-duration: $display-duration * $item-count;

	@keyframes controlAnimation-#{$item-count} {
		0% { @include inactive-button; }
	  #{100% * $transition-duration / $total-duration},
	  #{100% * $display-duration / $total-duration} { @include active-button; }
	  #{100% * ($display-duration + $transition-duration) / $total-duration},
	  100% { @include inactive-button; }
	}

	@keyframes galleryAnimation-#{$item-count} {
	  0% { opacity: 0; }
	  #{100% * $transition-duration / $total-duration},
	  #{100% * $display-duration / $total-duration} { opacity: 1; }
	  #{100% * ($display-duration + $transition-duration) / $total-duration},
	  100% { opacity: 0; }
	}

	.gallery {
		@for $j from 1 through $item-count {
			.control-operator:nth-of-type(#{$j}):target ~ .item:nth-of-type(#{$j}) { pointer-events: auto; opacity: 1; }
			.control-operator:nth-of-type(#{$j}):target ~ .controls .control-button:nth-of-type(#{$j}) { @extend %active-button; }
		}
	}

	.items-#{$item-count} {
		&.autoplay .control-button { animation: controlAnimation-#{$item-count} #{$total-duration}s infinite; }
		&.autoplay .item { animation: galleryAnimation-#{$item-count} #{$total-duration}s infinite; }

		@for $j from 1 through $item-count {
			.control-button:nth-of-type(#{$j}),
			.item:nth-of-type(#{$j}) {
				$delay: #{($j - 1) * ($display-duration) - $transition-duration}s;

				animation-delay: #{$delay};
			}
		}
	}
}

.gallery {
	.item:first-of-type { position: static; pointer-events: auto; opacity: 1; } // Set the first item to be visible
	.item { position: absolute; top: 0; left: 0; width: 100%; height: 100%;  pointer-events: none; opacity: 0; transition: opacity .5s; }

	.control-operator { display: none; } // Stop hash links from scrolling up and down

	.control-operator:target ~ .item { pointer-events: none; opacity: 0; animation: none; } // Unselected image

	.control-button:first-of-type { @extend %active-button; }
	.control-operator:target ~ .controls .control-button { @extend %inactive-button; animation: none; } // Kill button animation when there is a selected image
}