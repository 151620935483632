﻿@import "_variables.scss";

.calculator {
    background: $clarke-white;
    border: 0 none;
    border-radius: 10px;
    margin: 25px auto;

    .calc-container {
        padding: 10px;

        hr {
            border-top-color: #ccc;
            border-bottom: 1px solid #fff;
        }

        div.validation-summary-errors, .radio-validation-error {
            font-weight: 700;
            color: $clarke-red;
        }

        .input-validation-error {
            background-color: $clarke-red-45;

            &::placeholder {
                color: $clarke-black;
            }
        }

        .field-validation-error {
            font-weight: 700;
            color: $clarke-red;

            &.text-danger {
                clear: both;
            }
        }

        .calc-group {
            padding: 15px 0;
            border-bottom: solid 2px $clarke-gray;

            &:last-child {
                border-bottom: none 0 transparent;
            }

            abbr {
                color: $clarke-gray-65;
            }

            abbr[title] {
                text-decoration-color: $clarke-red;
            }

            label {
                font-weight: 400;

                abbr {
                    font-weight: 400;
                }

                small {
                    display: inline-block;

                    &.field-desc {
                        margin-bottom: 1rem;
                    }
                }
            }

            .sub-group-label {
                label {
                    font-weight: 600;
                }

                abbr {
                    font-weight: 600;
                }
            }

            [type="color"], [type="date"], [type="datetime-local"], [type="datetime"], [type="email"], [type="month"], [type="number"], [type="password"], [type="search"], [type="tel"], [type="text"], [type="time"], [type="url"], [type="week"], select, textarea {
                &.has-desc {
                    margin-bottom: 0;
                }
            }
        }

        .calc-result-table {
            table {
                border: solid 2px $clarke-black;

                td, th {
                    border: solid 1px $clarke-black;
                }

                th {
                    border-bottom: solid 3px $clarke-black;
                }
            }
        }
    }
}