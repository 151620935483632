﻿div.designMode {

    .white-header-container, .white-header {
        height: auto;
        max-height: none;
    }

    .copyright-container {
        height: auto;
    }

    .red-header-container.hide-for-large {
        display: block !important;
        height: auto;

        #mobile-navigation-panel {
            display: none;

            &.is-open {
                display: block;
            }
        }
    }

    footer.footer {
        max-height: none;
        height: auto;
    }

    .masthead, .call-to-action-container, .container-w-BG {


        div.backImg {
            display: block;

            img {
                max-height: 100px;
            }
        }
    }

    .home-page-container {
        .service-parts-locator {
            .service-parts-locator-inner {
                height: auto;
            }
        }

        .about-feature {
            .about-feature-inner {
                height: auto;
            }
        }

        .featured-news {
            .featured-news-article {
                 height: auto;
            }
        }
    }

    table.rdTable {
        tbody, thead, tfoot {
            background:transparent;
            border: 1px dashed #ff0;
        }
    }
}

.masthead, .call-to-action-container, div.container-w-BG {
    div.backImg {
        display: none;
    }
}

div.login-view {
    display: inline-block;
}


div.container-w-BG {

    &.whiteGradient {
        background-image: linear-gradient(rgba(255,255,255,.8),rgba(255,255,255,.8));
    }

    &.grayGradient {
        background-image: linear-gradient(rgba(0,0,0,.65),rgba(0,0,0,.65));
    }
}

/*2017-11-29 - Request from Mike Mathes to darken the placeholder values site-wide. - DSSmith*/
input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  opacity: 1;
}
input::-moz-placeholder { /* Firefox 19+ */
  opacity: 1;
}
input::-ms-input-placeholder{ /* Edge */
  opacity: 1;
}
input:-ms-input-placeholder { /* IE */
  opacity: 1;
}
input:-moz-placeholder { /* Firefox 18- */
  opacity: 1;
}
input::placeholder { /* Desktop Chrome, Desktop Firefox, iOS Safari, and IE10+  */
  opacity: 1;
}