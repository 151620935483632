@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Roboto:wght@400;500;700&display=swap');

$fa-font-path: "/ResourcePackages/clarkef/assets/dist/fonts" !default;
$dist-images: "/ResourcePackages/clarkef/assets/dist/images";
$breakpoint-classes: (small medium large xlarge xxlarge);

@import '../../../../../node_modules/motion-ui/motion-ui.scss';


@import "normalize.scss";
@import "../../../../../node_modules/font-awesome/scss/font-awesome";

@import "../../../../../node_modules/foundation-sites/scss/util/util";
// Global variables and styles
@import "../../../../../node_modules/foundation-sites/scss/global";
@import "../../../../../node_modules/foundation-sites/scss/foundation";

// Components
@import "../../../../../node_modules/foundation-sites/scss/grid/grid";
@import "../../../../../node_modules/foundation-sites/scss/typography/typography";
@import "../../../../../node_modules/foundation-sites/scss/forms/forms";
@import "../../../../../node_modules/foundation-sites/scss/components/accordion-menu";
@import "../../../../../node_modules/foundation-sites/scss/components/accordion";
@import "../../../../../node_modules/foundation-sites/scss/components/badge";
@import "../../../../../node_modules/foundation-sites/scss/components/breadcrumbs";
@import "../../../../../node_modules/foundation-sites/scss/components/button-group";
@import "../../../../../node_modules/foundation-sites/scss/components/button";
@import "../../../../../node_modules/foundation-sites/scss/components/callout";
@import "../../../../../node_modules/foundation-sites/scss/components/card";
@import "../../../../../node_modules/foundation-sites/scss/components/close-button";
@import "../../../../../node_modules/foundation-sites/scss/components/drilldown";
@import "../../../../../node_modules/foundation-sites/scss/components/dropdown-menu";
@import "../../../../../node_modules/foundation-sites/scss/components/dropdown";
@import "../../../../../node_modules/foundation-sites/scss/components/flex-video";
@import "../../../../../node_modules/foundation-sites/scss/components/flex";
@import "../../../../../node_modules/foundation-sites/scss/components/float";
@import "../../../../../node_modules/foundation-sites/scss/components/label";
@import "../../../../../node_modules/foundation-sites/scss/components/media-object";
@import "../../../../../node_modules/foundation-sites/scss/components/menu-icon";
@import "../../../../../node_modules/foundation-sites/scss/components/menu";
@import "../../../../../node_modules/foundation-sites/scss/components/off-canvas";
@import "../../../../../node_modules/foundation-sites/scss/components/orbit";
@import "../../../../../node_modules/foundation-sites/scss/components/pagination";
@import "../../../../../node_modules/foundation-sites/scss/components/progress-bar";
@import "../../../../../node_modules/foundation-sites/scss/components/responsive-embed";
@import "../../../../../node_modules/foundation-sites/scss/components/reveal";
@import "../../../../../node_modules/foundation-sites/scss/components/slider";
@import "../../../../../node_modules/foundation-sites/scss/components/sticky";
@import "../../../../../node_modules/foundation-sites/scss/components/switch";
@import "../../../../../node_modules/foundation-sites/scss/components/table";
@import "../../../../../node_modules/foundation-sites/scss/components/tabs";
@import "../../../../../node_modules/foundation-sites/scss/components/thumbnail";
@import "../../../../../node_modules/foundation-sites/scss/components/title-bar";
@import "../../../../../node_modules/foundation-sites/scss/components/tooltip";
@import "../../../../../node_modules/foundation-sites/scss/components/top-bar";
@import "../../../../../node_modules/foundation-sites/scss/components/visibility";

@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-menu;
@include foundation-menu-icon;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
//@include foundation-flex-grid;

@import "gallery-variables";
@import "gallery";
@import "gallery.build";
@import "gallery.theme";

@import "_variables.scss";

@import "clarke-fire";
@import "_Header-and-Navigation.scss";
@import "_home.scss";
@import "_pump-room.scss";
@import "_user-registration.scss";
@import "_calculators.scss";
@import "_product-list.scss";
@import "_dynamic-pages.scss";

@import "SFOverride.scss";