﻿@import "_variables.scss";

/******************************/
/***** HOME PAGE CONTENT *****/
/****************************/

.home-page-container {

    .hero {
        background: $clarke-black;
        overflow: hidden;

        .orbit.hero-slides {
            margin: 0 auto;
            max-width: 1920px;
            position: relative;
            overflow: hidden;

            ul.orbit-container {
                li.orbit-slide {
                    height: 560px;

                    background: {
                        color: $clarke-dark-gray;
                        repeat: no-repeat;
                        size: cover;
                    }

                    h1, h2 {
                        display: inline-block;
                        position: relative;
                        width: auto;
                        background-color: rgba(41,52,60,.75);
                        color: $clarke-white;
                        overflow: hidden;
                        margin: 0;
                        padding: 0;
                    }

                    h1 {
                        font: {
                            family: $font-stack-open-sans;
                            weight: 300;
                        }
                    }

                    h2 {
                        font: {
                            family: $font-stack-open-sans;
                            weight: 300;
                        }
                    }

                    .orbit-hero-slide-actions {
                        a {
                            margin: 0 8px 0 0;
                        }
                    }
                }
            }
        }

        .orbit-bullets {
            top: -55px;
            margin: 0;

            button {
                background-color: transparent;
                border: solid 1px $clarke-white;
                width: 1.0rem;
                height: 1.0rem;
            }

            button.is-active {
                background-color: $clarke-white;
            }
        }
    }

    .feature-tiles {
        height: auto;
        background: $clarke-black;

        .feature-tiles-inner {
            max-width: 1920px;
            margin: 0 auto;
        }

        .feature-tile {
            height: auto;
            min-height: 160px;

            background: {
                size: cover;
                repeat: no-repeat;
                position-x: center;
                position-y: top;
            }

            border-style: solid;
            border-color: $clarke-black;

            border: {
                top-width: 10px;
                right-width: 5px;
                bottom-width: 10px;
                left-width: 5px;
            }
        }

        .feature-tile:first-child {
            border-left: 0px none;
        }

        .feature-tile:last-child {
            border-right: 0px none;
        }

        .feature-tile.columns {
            padding: 0;
        }

        a.feature-tile-inner, a.feature-tile-inner span.feature-tile-title, a.feature-tile-inner span.feature-tile-icon {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            text-align: center;
        }

        a.feature-tile-inner {
            span.feature-tile-title {
                font: {
                    family: $font-stack-open-sans;
                    weight: 700;
                }

                text-transform: uppercase;
            }

            span.feature-tile-icon {
            }
        }

        a.feature-tile-inner, a.feature-tile-inner:link, a.feature-tile-inner:visited {
            color: $clarke-white;
        }

        a.feature-tile-inner:hover, a.feature-tile-inner:active {
            color: $clarke-red;
        }

        .nav-button {
            height: 140px;
            /*width: 292px;*/
            background-image: linear-gradient(0deg,rgba(0,0,0,.65),rgba(0,0,0,.65)),url('/img/cogs.jpg');
            /*margin-right: 10px;*/
            margin-top: 10px;

            &:last-child {
                margin-right: 0;
            }

            .title {
                font-family: $font-stack-open-sans;
                font-size: 16.7px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 3.0;
                letter-spacing: -0.5px;
                text-align: left;
                color: $clarke-white;
                padding: 15px 100px 0 100px;
            }

            i {
                font-size: 50px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.0;
                letter-spacing: -1.4px;
                text-align: left;
                color: $clarke-white;
                padding: 5px 110px;
            }
        }
    }

    .home-products-container {
        background-color: $clarke-black;
        border-bottom: 10px solid $clarke-black;

        .home-products-container-inner {
            max-width: 1920px;
            margin: 0 auto;
        }

        .product-finder {
            height: auto;

            background: {
                color: $clarke-white;
                size: cover;
                repeat: no-repeat;
                position-x: center;
                position-y: top;
            }

            h5 {
                font-family: $font-stack-open-sans;
                font-size: 16.7px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 3.0;
                letter-spacing: -0.5px;
                text-align: left;
                color: $clarke-red;
                text-transform: uppercase;

                i {
                    font-size: 20.8px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.3;
                    letter-spacing: -0.6px;
                    text-align: left;
                    color: #414141;
                    margin-left: 17px;
                }
            }

            h3, p {
                color: $clarke-dark-gray;
            }

            h3 {
                font: {
                    family: $font-stack-open-sans;
                    size: 33.33px;
                    weight: 300;
                }

                margin: 0 0 10px 0;
            }

            p {
                margin: 0 0 10px 0;
                font-weight: 400;
            }

            ul {
                color: $clarke-red;
            }

            li {
                text-indent: -5px;

                a {
                    font-family: $font-stack-open-sans;
                    font-size: 14.6px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    text-align: left;
                    color: $clarke-red;
                }
            }
        }

        .product-quick-search {
            height: auto;
            background-color: $clarke-red;


            h3 {
                font: {
                    family: $font-stack-open-sans;
                    size: 33.33px;
                    weight: 300;
                }

                color: $clarke-white;
                margin: 0;
                padding-bottom: 10px;
            }

            fieldset.columns {
                padding: 0 0 0 0;
            }

            fieldset.pqs-pop {
                [type="checkbox"] + label, [type="radio"] + label {
                    margin-left: 0.2rem;
                    margin-right: 0.8rem;
                    font-weight: 300;
                }
            }

            fieldset.pqs-type {
                padding-right: 10px;
            }

            fieldset.pqs-emissions {
                padding-right: 10px;
            }

            .slider-container {
                .slider-label, .slider-min, .slider-max {
                    color: $clarke-white;
                }

                .slider-label {
                    width: 45px;
                }

                .slider-input {
                    width: 250px;
                }

                .slider {
                    background-color: $clarke-white;

                    .slider-fill {
                        background-color: $clarke-white;
                    }

                    .slider-handle {
                        background: $clarke-white;

                        input[type="text"] {
                            background: $clarke-white;
                            color: $clarke-red;
                        }
                    }
                }

                margin-top: 5px;
            }

            .slider-container.columns {
                padding: 0;
            }

            .slider-container.slider-rpm {
                padding: 5px 0 35px;
            }

            .slider-container.slider-power {
                padding-bottom: 30px;
            }



            label, legend {
                font-family: $font-stack-open-sans;
                font-size: 14.6px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.4px;
                text-align: left;
                color: $clarke-white;
            }

            h4 {
                color: $clarke-white;
            }

            select {
                height: 22px;
                font-family: $font-stack-open-sans;
                font-size: 14.6px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.4px;
                text-align: left;
                color: #2b2b2b;
                padding: 0;
                margin-top: 7px;
            }

            .slider-handle {
                background-color: $clarke-white;
            }

            .pqs-search-input.columns {
                padding: 0;
            }

            .pqs-search-button.go-by-form.columns {
                padding: 70px 0 0;
            }

            .pqs-search-button {
                .primary-button {
                    font-family: $font-stack-open-sans;
                    font-size: 12.5px;
                    font-weight: 700;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.3px;
                    text-align: left;
                    color: $clarke-red;
                    background: $clarke-white;
                    padding: 10px;
                    margin: 0 0 0 10px;
                }
            }

            .pqs-kwmn {
                .pqs-search-input, .pqs-search-button {
                    padding-top: 10px;
                }
            }

            .pqs-disclaimer {
                width: 100%;
                padding: 0px 0 0 0;

                p {
                    font-size: 11px;
                    color: #FFF;
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }

    .service-parts-locator {
        background-color: $clarke-black;
        border-bottom: 10px solid $clarke-black;

        .service-parts-locator-inner {
            background: {
                color: $clarke-dark-gray;
                size: cover;
                repeat: no-repeat;
                position-x: center;
                position-y: center;
            }

            height: 350px;
            max-width: 1920px;
            margin: 0 auto;
        }

        h5 {
            font-family: $font-stack-open-sans;
            font-size: 16.7px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 3.0;
            letter-spacing: -0.5px;
            text-align: left;
            color: $clarke-white;
            text-transform: uppercase;
            margin-bottom: 0;

            i {
                font-size: 25px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 2.0;
                letter-spacing: -0.7px;
                text-align: left;
                color: $clarke-white;
                margin-left: 15px;
            }
        }

        h2 {
            color: $clarke-white;
            margin-top: 10px;
            margin-bottom: 20px;
        }

        p {
            color: $clarke-white;
            margin-bottom: 20px;
        }

        ul {
            color: $clarke-white;
        }

        li {
            text-indent: -5px;

            a {
                font-family: $font-stack-open-sans;
                font-size: 14.6px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: -0.4px;
                text-align: left;
                color: $clarke-white;
            }
        }

        .input {
            padding-left: 0;

            input {
                height: 35px;
                font-family: $font-stack-open-sans;
                font-size: 12px;
            }
        }

        .primary-button {
            padding: 10px 20px;
            color: $clarke-white;
            font-weight: 700;
        }

        .image {
            padding-top: 45px;
        }
    }

    .reference-tools-container {
        background-color: $clarke-black;
        height: auto;
        border-bottom: 10px solid $clarke-black;

        .reference-tools {
            background-color: #e2dddd;
            max-width: 1920px;
            margin: 0 auto;

            .reference-tool {
                height: auto;
                overflow: hidden;

                .reference-tool-inner {
                    padding: 20px 30px;
                    background: $clarke-white;

                    h5 {
                        font-family: $font-stack-open-sans;
                        font-size: 16.7px;
                        font-weight: bold;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: 1;
                        letter-spacing: -0.5px;
                        text-align: left;
                        color: $clarke-red;
                        text-transform: uppercase;
                        margin-bottom: 10px;
                        min-height: 45px;

                        i {
                            margin-left: 15px;
                            font-size: 25px;
                            line-height: 1;
                            letter-spacing: -0.7px;
                            color: #2a2a2a;
                        }
                    }

                    h3 {
                        font-family: $font-stack-open-sans;
                        font-size: 25px;
                        font-weight: 300;
                        font-style: normal;
                        font-stretch: normal;
                        line-height: normal;
                        letter-spacing: -0.7px;
                        text-align: left;
                        color: #272727;
                        margin-bottom: 15px;
                    }

                    .rt-description {
                        min-height: 60px;

                        p {
                            font-family: $font-stack-open-sans;
                            font-size: 14.6px;
                            font-weight: normal;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: normal;
                            letter-spacing: -0.4px;
                            text-align: left;
                            color: #303030;
                            margin-bottom: 15px;
                        }
                    }

                    .rt-actions {
                        .input {
                            padding-left: 0;

                            input {
                                height: 35px;
                                font-family: $font-stack-open-sans;
                                font-size: 12px;
                            }
                        }

                        .search-button {
                            padding-left: 0;

                            a {
                                @extend .primary-button;
                                padding: 10px 20px;
                                color: $clarke-white;
                                font-weight: 700;
                            }
                        }
                    }

                    .rt-links {
                        ul {
                            color: $clarke-red;

                            margin: {
                                bottom: 0;
                            }
                        }

                        li {
                            text-indent: -5px;
                        }

                        a {
                            font-family: $font-stack-open-sans;
                            font-size: 14.6px;
                            font-weight: bold;
                            font-style: normal;
                            font-stretch: normal;
                            line-height: 1.43;
                            letter-spacing: -0.4px;
                            text-align: left;
                            color: $clarke-red;
                        }
                    }
                }
            }
        }
    }

    .customer-support-center {
        background-color: $clarke-black;
        border-bottom: 10px solid $clarke-black;

        .customer-support-center-inner {
            height: auto;

            background: {
                color: $clarke-dark-gray;
                size: cover;
                repeat: no-repeat;
                position-x: center;
                position-y: center;
            }

            max-width: 1920px;
            margin: 0 auto;

            h3 {
                font: {
                    size: 33.33px;
                    weight: 300;
                }

                margin-bottom: 10px;
            }

            h4 {
                font: {
                    size: 25.00px;
                    weight: 300;
                }

                text-transform: none;
            }

            h5 {
                font: {
                    family: $font-stack-open-sans;
                    size: 16.7px;
                    weight: 700;
                    style: normal;
                    stretch: normal;
                }

                color: $clarke-white;
                line-height: 3.0;
                letter-spacing: -0.5px;
                text-align: left;
                text-transform: uppercase;
                margin-bottom: 0px;

                i {
                    font: {
                        size: 25px;
                        weight: 700;
                        style: normal;
                        stretch: normal;
                    }

                    color: $clarke-white;
                    line-height: 2.0;
                    letter-spacing: -0.7px;
                    text-align: left;
                    margin-left: 20px;
                }
            }

            p {
                margin-bottom: 5px;
            }

            li {
                text-indent: -5px;
                color: $clarke-white;

                a {
                    font: {
                        family: $font-stack-open-sans;
                        size: 14.58px;
                        weight: 700;
                        style: normal;
                        stretch: normal;
                    }

                    line-height: 1.43;
                    letter-spacing: -0.4px;
                    text-align: left;
                }

                a:link, a:visited {
                    color: $clarke-white;
                }

                a:hover, a:active {
                    color: $clarke-red;
                }
            }

            .primary-button, .secondary-button, .tertiary-button {
                font-weight: 700;
                margin: 0 8px 5px 0;
            }

            .csc-faq {
                .csc-faq-inner {
                    .cf-heading {
                        h3, h5, p {
                            color: $clarke-white;
                        }
                    }

                    .cf-content {
                    }
                }
            }

            .csc-contact {
                .csc-contact-inner {
                    .cc-content {
                        h4, li {
                            color: $clarke-white;
                        }
                    }
                }
            }
        }
    }

    .about-feature {
        background-color: $clarke-black;
        border-bottom: 10px solid $clarke-black;

        .about-feature-inner {
            height: 488px;

            background: {
                color: $clarke-white;
                size: cover;
                repeat: no-repeat;
                position-x: center;
                position-y: top;
            }

            max-width: 1920px;
            margin: 0 auto;
        }

        h2 {
            display: inline-block;

            font: {
                family: $font-stack-open-sans;
                size: 45.83px;
                weight: 500;
            }

            color: $clarke-white;
            background: $clarke-red;
            margin: 0;
        }

        h5 {
            font: {
                family: $font-stack-open-sans;
                size: 16.7px;
                weight: 700;
                style: normal;
                stretch: normal;
            }

            color: $clarke-red;
            line-height: 3.0;
            letter-spacing: -0.5px;
            text-align: left;
            text-transform: uppercase;
            margin-bottom: 0px;

            i {
                font: {
                    size: 25px;
                    weight: 700;
                    style: normal;
                    stretch: normal;
                }

                color: $clarke-black;
                line-height: 2.0;
                letter-spacing: -0.7px;
                text-align: left;
                margin-left: 20px;
            }
        }

        ul {
            color: $clarke-red;

            li {
                text-indent: -5px;

                a {
                    font-family: $font-stack-open-sans;
                    font-size: 14.6px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: -0.4px;
                    text-align: left;
                    color: $clarke-red;
                }

                a:link, a:visited {
                    color: $clarke-red;
                }

                a:hover, a:active {
                    color: $clarke-red-hover;
                }
            }
        }

        .primary-button, .secondary-button, .tertiary-button {
            font-weight: 700;
            margin: 0 8px 5px 0;
        }



        .heading {
            margin-top: 50px;
            padding-left: 45px;
            font-family: $font-stack-open-sans;
            font-size: 16.7px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 3.0;
            letter-spacing: -0.5px;
            text-align: left;
            color: $clarke-red;
            text-transform: uppercase;
            margin-bottom: 75px;
        }

        .statement-container {
            padding-left: 0;

            .text {
                font-family: $font-stack-open-sans;
                font-size: 25px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.08;
                letter-spacing: -0.7px;
                text-align: left;
                color: #1f1f1f;
                margin-left: 50px;
                margin-top: 25px;
                width: 70%;
            }
        }

        .descriptive-statement-container {
            width: 625px;
            height: 153px;
            background-color: $clarke-red;

            p {
                font-family: $font-stack-open-sans;
                font-size: 45.8px;
                font-weight: 500;
                font-style: normal;
                font-stretch: normal;
                line-height: 0.91;
                letter-spacing: -1.3px;
                text-align: left;
                color: $clarke-white;
                padding: 35px 35px 35px 60px;
            }
        }

        .learn-more {
            .primary-button {
                padding: 13px 38px 13px 28px;
            }

            ul {
                color: $clarke-red;
            }

            li {
                text-indent: -5px;

                a {
                    font-family: $font-stack-open-sans;
                    font-size: 14.6px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.43;
                    letter-spacing: -0.4px;
                    text-align: left;
                    color: $clarke-red;
                }
            }
        }
    }

    .case-studies {
        background-color: $clarke-black;
        height: auto;
        border-bottom: 10px solid $clarke-black;

        .case-studies-inner {
            background-color: $clarke-dark-gray;
            max-width: 1920px;
            margin: 0 auto;

            h5 {
                font: {
                    family: $font-stack-open-sans;
                    size: 16.7px;
                    weight: 700;
                    style: normal;
                    stretch: normal;
                }

                line-height: 1;
                letter-spacing: -0.5px;
                text-align: left;
                color: $clarke-white;
                margin-bottom: 15px;

                i {
                    font-size: 21.8px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.78;
                    letter-spacing: -0.6px;
                    text-align: left;
                    color: $clarke-white;
                    margin-left: 14px;
                }
            }

            .case-study {
                .case-study-inner {
                    background: $clarke-white;
                    padding: 40px 30px 20px 30px;

                    .cs-content {
                        height: auto;
                        overflow: hidden;
                    }

                    img {
                        float: right;
                        margin: 0 0 5px 5px;
                        padding: 0;
                    }

                    h6 {
                        font: {
                            family: $font-stack-open-sans;
                            size: 12.50px;
                            weight: bold;
                        }

                        color: $clarke-red;
                        text-transform: uppercase;
                        margin: 0 0 15px 0;
                        padding: 0;
                    }

                    h4 {
                        font: {
                            family: $font-stack-open-sans;
                            size: 25.00px;
                            weight: 300;
                        }

                        color: $clarke-dark-gray;
                        text-transform: none;
                        margin: 0 0 10px 0;
                        padding: 0;
                    }

                    blockquote, p {
                        font: {
                            family: $font-stack-open-sans;
                            size: 14.58px;
                            weight: 300;
                        }

                        color: $clarke-dark-gray;
                        text-transform: none;
                        line-height: 1;
                        border: none 0;
                        margin: 0 0 15px 0;
                        padding: 0;
                    }


                    p span.attribution {
                        font: {
                            family: $font-stack-open-sans;
                            size: 10.42px;
                            weight: 700;
                        }

                        color: $clarke-dark-gray;
                        text-transform: uppercase;
                    }

                    a.more {
                        font: {
                            family: $font-stack-open-sans;
                            size: 14.58px;
                            weight: 700;
                        }

                        color: $clarke-red;
                        text-transform: none;
                    }

                    a.more:link, a.more:visited {
                        color: $clarke-red;
                    }

                    a.more:hover, a.more:active {
                        color: $clarke-red-hover;
                    }
                }
            }
            /*.title {
                font-family: $font-stack-open-sans;
                font-size: 12.5px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 4.0;
                letter-spacing: -0.3px;
                text-align: left;
                color: $clarke-red;
                text-transform: uppercase;
            }

            .location {
                font-family: $font-stack-open-sans;
                font-size: 25px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.7px;
                text-align: left;
                color: #272727;
                margin-bottom: 15px;
            }

            .text {
                p {
                    font-family: $font-stack-open-sans;
                    font-size: 14.6px;
                    font-weight: 300;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: normal;
                    letter-spacing: -0.4px;
                    text-align: left;
                    color: #303030;
                    float: left;
                    margin-bottom: 20px;
                }

                img {
                    float: right;
                }

                .name {
                    font-family: $font-stack-montserrat;
                    font-size: 10.4px;
                    font-weight: bold;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 4.8;
                    letter-spacing: -0.3px;
                    text-align: left;
                    color: #252525;
                    text-transform: uppercase;
                }
            }

            a {
                font-family: $font-stack-open-sans;
                font-size: 14.6px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: -0.4px;
                text-align: left;
                color: $clarke-red;
            }*/
        }
    }

    .featured-news {
        background-color: $clarke-black;
        border-bottom: 10px solid $clarke-black;

        .featured-news-article {
            height: 450px;

            background: {
                color: $clarke-dark-gray;
                size: cover;
                repeat: no-repeat;
                position-x: center;
                position-y: top;
            }

            max-width: 1920px;
            margin: 0 auto;

            h5 {
                font: {
                    family: $font-stack-open-sans;
                    size: 16.67px;
                    weight: 700;
                    style: normal;
                    stretch: normal;
                }

                line-height: 3.0;
                letter-spacing: -0.5px;
                text-align: left;
                color: $clarke-white;
                text-transform: uppercase;
                padding: 0 0 25px 50px;
                margin: 0;

                i {
                    font-size: 25px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.78;
                    letter-spacing: -0.7px;
                    text-align: left;
                    color: $clarke-white;
                    margin-left: 15px;
                }
            }

            .article-summary {
                display: inline-block;
                opacity: 0.85;
                background-color: $clarke-red;

                h6 {
                    font: {
                        family: $font-stack-open-sans;
                        size: 12.50px;
                        weight: 700;
                        style: normal;
                        stretch: normal;
                    }

                    line-height: 1;
                    letter-spacing: -0.3px;
                    text-align: left;
                    color: $clarke-white;
                    margin-bottom: 7px;
                    text-transform: uppercase;
                }

                h3 {
                    font: {
                        family: $font-stack-open-sans;
                        size: 37.50px;
                        weight: 300;
                        style: normal;
                        stretch: normal;
                    }

                    color: $clarke-white;
                    line-height: 45px;
                    margin-bottom: 20px;

                    a {
                        color: $clarke-white;
                        transition: all 0.5s ease-in-out 0s;
                    }

                    a:hover {
                        color: $clarke-red-hover;
                    }
                }

                p {
                    font: {
                        family: $font-stack-open-sans;
                        size: 14.58px;
                        weight: 300;
                        style: normal;
                        stretch: normal;
                    }

                    line-height: normal;
                    letter-spacing: -0.4px;
                    text-align: left;
                    color: $clarke-white;
                    margin: 0;
                }
            }

            .article-action {
                padding: 20px 50px 0;

                .primary-button, .secondary-button, .tertiary-button {
                    font-weight: 700;
                    margin: 0 8px 5px 0;
                }
            }
        }
    }

    .home-news-list {
        background-color: $clarke-black;
        height: auto;
        /*border-bottom: 10px solid $clarke-black;*/

        .home-news-list-inner {
            background-color: #e2dddd;
            max-width: 1920px;
            margin: 0 auto;

            h5 {
                font: {
                    family: $font-stack-open-sans;
                    size: 16.7px;
                    weight: 700;
                    style: normal;
                    stretch: normal;
                }

                line-height: 1;
                letter-spacing: -0.5px;
                text-align: left;
                color: $clarke-red;
                margin-bottom: 15px;

                i {
                    font-size: 21.8px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.78;
                    letter-spacing: -0.6px;
                    text-align: left;
                    color: $clarke-red;
                    margin-left: 14px;
                }
            }

            .home-news {
                .home-news-inner {
                    background: $clarke-white;
                    padding: 40px 30px 20px 30px;

                    .hn-content {
                        height: auto;
                        overflow: hidden;
                    }

                    img {
                        float: right;
                        margin: 0 0 5px 5px;
                        padding: 0;
                    }

                    h6 {
                        font: {
                            family: $font-stack-open-sans;
                            size: 12.50px;
                            weight: bold;
                        }

                        color: $clarke-dark-gray;
                        text-transform: uppercase;
                        margin: 0 0 15px 0;
                        padding: 0;
                    }

                    h4, h4 a {
                        font: {
                            family: $font-stack-open-sans;
                            size: 25.00px;
                            weight: 300;
                        }

                        color: $clarke-red;
                        text-transform: none;
                        margin: 0 0 10px 0;
                        padding: 0;
                    }

                    h4 a:link, h4 a:visited {
                        color: $clarke-red;
                    }

                    h4 a:hover, h4 a:active {
                        color: $clarke-red-hover;
                    }

                    blockquote, p {
                        font: {
                            family: $font-stack-open-sans;
                            size: 14.58px;
                            weight: 300;
                        }

                        color: $clarke-dark-gray;
                        text-transform: none;
                        line-height: 1;
                        border: none 0;
                        margin: 0 0 15px 0;
                        padding: 0;
                    }


                    p span.attribution {
                        font: {
                            family: $font-stack-open-sans;
                            size: 10.42px;
                            weight: 700;
                        }

                        color: $clarke-dark-gray;
                        text-transform: uppercase;
                    }

                    a.more {
                        font: {
                            family: $font-stack-open-sans;
                            size: 14.58px;
                            weight: 700;
                        }

                        color: $clarke-red;
                        text-transform: none;
                    }

                    a.more:link, a.more:visited {
                        color: $clarke-red;
                    }

                    a.more:hover, a.more:active {
                        color: $clarke-red-hover;
                    }
                }
            }
        }
    }
}
@media screen and (min-width:1670px) {
    .home-page-container { 

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-input {width:500px;}
                }
            }
        }

        .reference-tools-container {
            .reference-tools {
                .reference-tool {
                    .reference-tool-inner {
                        height:340px;
                    }
                }
            }
        }

        .case-studies .case-studies-inner .case-study .cs-content { min-height:180px; }

        .home-news-list .home-news-list-inner .home-news .hn-content { min-height:180px; }
    }
}

@media screen and (min-width:1440px) and (max-width:1669px) {
    .home-page-container { 

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-input {width:385px;}
                }
            }
        }

        .reference-tools-container {
            .reference-tools {
                .reference-tool {
                    .reference-tool-inner {
                        height:340px;
                    }
                }
            }
        }

        .case-studies .case-studies-inner .case-study .cs-content { min-height:180px; }

        .home-news-list .home-news-list-inner .home-news .hn-content { min-height:180px; }
    }
}
@media screen and (min-width:1140px) and (max-width:1439px) {
    .home-page-container {

        .reference-tools-container .reference-tools .reference-tool .reference-tool-inner {height:375px;}

        .case-studies .case-studies-inner .case-study .cs-content { min-height:180px; }

        .home-news-list .home-news-list-inner .home-news .hn-content { min-height:180px; }
    }
}
@media screen and (min-width:1024px) and (max-width:1320px) {
    .reference-tool {
        .rt-actions {
            .input.large-8.columns, .search-button.large-4.columns {
                width:100%;
            }
        }
    }
}
@media screen and (min-width:1025px) and (max-width:1164px) {
    .home-page-container { .reference-tools-container .reference-tools .reference-tool .reference-tool-inner h5 i {display:none;} }
}
@media screen and (min-width:1024px) and (max-width:1139px) {
    .home-page-container {

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-input {width:200px;}
                }
            }
        }

        .reference-tools-container .reference-tools .reference-tool .reference-tool-inner {height:360px;}

        .case-studies .case-studies-inner .case-study .cs-content { min-height:230px; }

        .home-news-list .home-news-list-inner .home-news .hn-content { min-height:230px; }
    }
}
@media screen and (min-width:1024px) {
    .home-page-container {

        .hero {
            .orbit.hero-slides {
                height:553px;
                ul.orbit-container {
                    li.orbit-slide {
                        height: 553px;
                        h1 {font-size:52.08px;line-height:60px;}
                        h2 {font-size:25.00px;line-height:normal;}
                        .orbit-hero-slide-headline {padding-top:203px;h1{padding:12px 50px;}}
                        .orbit-hero-slide-tagline  {padding-top:  0px;h2{padding: 8px 50px;}}
                        .orbit-hero-slide-actions  {padding:22px 0 0 50px;}
                    }
                }
            }            
        }

        .feature-tiles {
            a.feature-tile-inner {
                span.feature-tile-title {
                    font-size:16.67px;
                    padding-top:30px;
                }
                span.feature-tile-icon {
                    font-size:50.00px;
                }
            }
        }

        .home-products-container {
            .product-finder, .product-quick-search {height:570px;min-height:570px;max-height:570px;overflow:hidden;}
            .product-finder {
                padding: 30px 30px 30px 50px;
            }
            .product-quick-search {
                padding: 30px 50px 20px 50px;
                fieldset.pqs-pop {
                    padding-right:10px;
                }
                fieldset.pqs-type {
                    padding-right:10px;
                }
                fieldset.pqs-emissions {
                    padding-right:10px;
                }                
            }
        }

        .service-parts-locator {
            
            .service-parts-locator-inner {
                height:350px;
                padding:30px 40px;
            }
        }

        .reference-tools-container {
            .reference-tools {
                padding: 30px 50px;
                .reference-tool {
                    .reference-tool-inner {
                    }
                }
            }
        }

        .customer-support-center {            
            .customer-support-center-inner {
                min-height: 360px;
                padding:30px 25px;
                .csc-faq{
                    .csc-faq-inner {
                        .cf-heading {                            
                        }
                        .cf-content {
                            padding-top:55px;
                        }
                    }
                }
                .csc-contact {
                    .csc-contact-inner {
                        .cc-content {                            
                        }
                    }
                }
            }
        }

        .about-feature {
            .about-feature-inner {
                min-height:500px;
            }
            h2 {
                padding:30px 20px 30px 50px;
                line-height:45px;
                max-width:90%;
            }
            h3 {
                padding:20px 20px 0 50px;
                line-height:28px;
                max-width:90%;
            }
            .about-feature-inner {
                padding:30px 0;
                .columns {padding:{left:0;right:0;}}
                .af-heading {
                    padding:0 50px 57px;
                }
                .af-statement {

                }
                .af-links {

                }
            }
        }

        .case-studies {
            .case-studies-inner {
                padding: 30px 40px;
                .cs-content {
                }
            }
        }

        .featured-news {
            .featured-news-article {
                padding: 35px 0;
                .article-summary {
                    max-width:45%;
                    padding:35px 20px 20px 50px;
                }
            }
        }

        .home-news-list {
            .home-news-list-inner {
                padding: 30px 40px;
                .hn-content {
                }
            }
        }
    }
}
@media screen and (min-width:640px) and (max-width:1023px) {    
    .home-page-container {

        .hero {
            .orbit.hero-slides {
                height:500px;
                ul.orbit-container {
                    li.orbit-slide {
                        height: 500px;
                        h1 {font-size:52.08px;line-height:56px;}
                        h2 {font-size:25.00px;line-height:normal;}
                        .orbit-hero-slide-headline {padding-top:167px;h1{padding: 8px 30px;}}
                        .orbit-hero-slide-tagline  {padding-top:  4px;h2{padding: 8px 30px;}}
                        .orbit-hero-slide-actions  {padding:12px 0 0 30px;}
                    }
                }
            }            
        }

        .feature-tiles {
            a.feature-tile-inner {
                span.feature-tile-title {                    
                    font-size:14.58px;
                    padding-top:15px;
                }
                span.feature-tile-icon {
                    font-size:50.00px;
                }
            }
        }

        .home-products-container {
            .product-finder, .product-quick-search {height:auto;min-height:unset;max-height:unset;overflow:hidden;}
            .product-finder {
                padding: 30px 50px 15px;
                border-bottom:0px none;
                h5 {margin-bottom:5px;}
            }
            .product-quick-search {
                padding: 20px 50px 30px;
                h3 {display:none;}
                fieldset.pqs-pop {
                    padding-right:10px;
                }
                fieldset.pqs-type {
                    padding-right:10px;
                }
                fieldset.pqs-emissions {
                    padding-right:10px;
                }
                .slider-container {
                    .slider-input {width:500px;}
                }
            }
        }

        .service-parts-locator {
            .service-parts-locator-inner {
                height:340px;
                padding:20px 30px;
            }
        }

        .reference-tools-container {
            .reference-tools {
                padding: 20px 30px;
                .reference-tool {
                    .reference-tool-inner {
                    }
                }
                .reference-tool.medium-wide {
                    padding-top:20px;
                    .reference-tool-inner {
                        height:310px;
                    }
                }
            }
        }

        .customer-support-center {
            .customer-support-center-inner {
                padding:30px 25px;
                .csc-faq{
                    .csc-faq-inner {
                        .cf-heading {                            
                        }
                        .cf-content {
                            padding-top:5px;
                        }
                    }
                }
                .csc-contact {
                    .csc-contact-inner {
                        .cc-content { 
                            padding-top:10px;
                        }
                    }
                }
            }
        }

        .about-feature {
            .about-feature-inner {  
                height:auto;              
            }
            h2 {
                padding:20px 20px 20px 50px;
                line-height:45px;
                max-width:90%;
            }
            h3 {
                padding:20px 20px 0 50px;
                line-height:28px;
                max-width:90%;
            }
            .about-feature-inner {
                padding:30px 0;
                .columns {padding:{left:0;right:0;}}
                .af-heading {
                    padding:0 50px 30px;
                }
                .af-statement {

                }
                .af-links {

                }
            }
        }

        .case-studies {
            .case-studies-inner {
                padding: 25px;
                .case-study {
                    display:block;
                    .cs-content {
                        min-height:230px;
                    }
                }
                .case-study-articles .case-study:last-child {display:none;}
            }
        }

        .featured-news {
            .featured-news-article {
                height:auto;
                padding: 35px 0;
                .article-summary {
                    max-width:75%;
                    padding:35px 20px 20px 50px;
                }
            }
        }

        .home-news-list {
            .home-news-list-inner {
                padding: 25px;
                .home-news {
                    display:block;
                    .hn-content {
                        min-height:230px;
                    }
                }
                .home-news-articles .home-news:last-child {display:none;}
            }
        }
    }
}
@media screen and (min-width:981px) and (max-width:1023px) {
    .home-page-container {
        .reference-tools-container .reference-tools .reference-tool {
            .reference-tool-inner {height:360px;}
        } 
        .case-studies .case-studies-inner .case-study .cs-content { min-height:180px; }
        .home-news-list .home-news-list-inner .home-news .hn-content { min-height:180px; }
    }
}
@media screen and (min-width:640px) and (max-width:980px) {
    .home-page-container {
        .reference-tools-container .reference-tools .reference-tool {
            .reference-tool-inner {height:360px;}
            .rt-actions { .input.medium-7.columns, .search-button.medium-5.columns { width:100%; } }
        }
        .case-studies .case-studies-inner .case-study .cs-content { min-height:230px; }
        .home-news-list .home-news-list-inner .home-news .hn-content { min-height:230px; }
    }
}
@media screen and (min-width:741px) and (max-width:845px) {
    .home-page-container { 

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-input {width:400px;}
                }
            }
        }
    }
}
@media screen and (min-width:640px) and (max-width:800px) {
    .home-page-container { .reference-tools-container .reference-tools .reference-tool .reference-tool-inner h5 i {display:none;} }
}
@media screen and (min-width:640px) and (max-width:740px) {
    .home-page-container { 

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-input {width:250px;}
                }
            }
        }
    }
}
@media screen and (max-width:639px) {
    .home-page-container {

        .hero {
            .orbit.hero-slides {
                height:400px;
                ul.orbit-container {
                    li.orbit-slide {
                        height: 400px;
                        h1 {font-size:33.33px;line-height:35px;}
                        h2 {font-size:20.83px;line-height:24px;}
                        .orbit-hero-slide-headline {padding-top:67px;h1{padding:12px 30px;}}
                        .orbit-hero-slide-tagline  {padding-top: 4px;h2{padding: 4px 30px;}}
                        .orbit-hero-slide-actions  {padding:19px 0 0 30px;}
                    }
                }                
            }            
        }

        .feature-tiles {
             a.feature-tile-inner {
                span.feature-tile-title {
                    font-size:10.42px;
                    padding-top:35px;
                }
                span.feature-tile-icon {
                    font-size:30.00px;
                }
            }
        }

        .home-products-container {
            .product-finder, .product-quick-search {height:auto;min-height:unset;max-height:unset;overflow:hidden;}
            .product-finder {
                padding: 30px 30px 10px;
                border-bottom:0px none;
                h5 {margin-bottom:5px;font-size:12.50px;}
                h3 {font-size:25px;}
            }
            .product-quick-search {
                padding: 20px 30px 30px;
                h3 {display:none;}
                fieldset.pqs-pop {
                    padding-right:10px;
                }
                fieldset.pqs-type {
                    padding-right:10px;
                }
                fieldset.pqs-emissions {
                    padding-right:10px;
                }
                .pqs-search-button.go-by-form.columns {padding:0 0 10px;}
                .pqs-kwmn {
                    .pqs-search-input, .pqs-search-button {
                        padding-top:0;
                    }
                }
            }
        }

        .service-parts-locator {
            padding-bottom:0;
            .service-parts-locator-inner {
                height:auto;
                padding:0 30px;
                h5 {font-size:12.50px}
                h2 {font-size:25px;margin:0 0 10px 0;}
                p {margin-bottom:5px;}
            }

        }

        .reference-tools-container {
            .reference-tools {
                padding: 0;
                .reference-tool.columns {padding:20px 0 0 0;}
                .reference-tool.columns:last-child {padding-bottom:20px;}
                .reference-tool {
                    .reference-tool-inner {
                        padding:30px 40px;
                        h5 {font-size:12.50px; i {font-size:20.83px;}}
                        h3 {font-size:25.00px; margin-bottom:0;}
                        .rt-description {p {font-size:14.58px}}
                    }
                }
            }
        }

        .customer-support-center {
            .customer-support-center-inner {
                padding: 5px 10px;
                h3 {font-size:25.00px;}
                h4 {font-size:25.00px; margin-bottom:5px;}
                h5 {font-size:12.50px; i {font-size:25.00px;}}
                .csc-faq{
                    .csc-faq-inner {
                        .cf-heading {                            
                        }
                        .cf-content {
                            padding-top:5px;
                        }
                    }
                }
                .csc-contact {
                    .csc-contact-inner {
                        .cc-content { 
                            padding-top:10px;
                        }
                    }
                }
            }
        }

        .about-feature {
            .about-feature-inner {  
            }
            h2 {
                font-size:25.00px;
                padding:20px 20px 20px 30px;
                line-height:30px;
                max-width:80%;
            }
            h3 {
                font-size:16.67px;
                padding:20px 20px 0 30px;
                line-height:20px;
                max-width:100%;
            }
            h5 {font-size:12.50px; i {font-size:25.00px;}}
            .about-feature-inner {
                height:auto;
                padding:20px 0;
                .columns {padding:{left:0;right:0;}}
                .af-heading {
                    padding:0 30px 20px;
                }
                .af-statement {

                }
                .af-links {
                    padding:0 30px;
                }
            }
        }

        .case-studies {
            .case-studies-inner {
                padding:25px;
                .case-study {
                    
                }
                .case-study-articles {
                    .case-study:nth-child(2) {display:none;}
                    .case-study:nth-child(3) {display:none;}
                }
            }
        }

        .featured-news {
            .featured-news-article {
                height:auto;
                padding: 35px 0;
                .article-summary {
                    max-width:85%;
                    padding:35px 20px 20px 50px;
                }
            }
        }

        .home-news-list {
            .home-news-list-inner {
                padding:25px;
                .home-news {
                    
                }
                .home-news-articles {
                    .home-news:nth-child(2) {display:none;}
                    .home-news:nth-child(3) {display:none;}
                }
            }
        }
    }   
}
@media screen and (min-width:420px) and (max-width:639px) {
    .home-page-container {
        .reference-tools-container .reference-tools .reference-tool .reference-tool-inner {height:290px;}

        
    }
}
@media screen and (min-width:451px) and (max-width:485px) {
    .home-page-container { 

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-input {width:200px;}
                }
            }
        }
    }
}
@media screen and (min-width:380px) and (max-width:450px) {
    .home-page-container { 

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-label {float:none;}
                    .slider-input {width:150px;}
                }
            }
        }
    }
}
@media screen and (max-width:419px) {
    .home-page-container {
        .reference-tools-container .reference-tools .reference-tool .reference-tool-inner {height:320px;}

        
    }
}
@media screen and (max-width:379px) {
    .home-page-container { 

        .home-products-container {
            .product-quick-search {
                .slider-container {
                    .slider-label {float:none;}
                    .slider-input {width:117px;}
                }
            }
        }
    }
}

/** DEPRECIATED... **/

    .hero {
        /*height: 560px;
        background-size: cover;
        background-image: url('/img/construction.jpg');*/

        /*.value-prop {
            width: 705px;
            height: 144px;
            opacity: 0.85;
            background-color: #161616;
            margin-top: 200px;
            margin-bottom: 6px;

            p {
                font-family: $font-stack-open-sans;
                font-size: 52.1px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.12;
                letter-spacing: -1.5px;
                text-align: left;
                color: $clarke-white;
                padding: 20px 45px;
            }
        }*/

        /*.register {
            width: 503px;
            height: 58px;
            opacity: 0.85;
            background-color: #161616;
            margin-bottom: 30px;

            p {
                font-family: $font-stack-open-sans;
                font-size: 25px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.7px;
                text-align: left;
                color: $clarke-white;
                padding: 15px 20px 15px 45px;
            }
        }*/

        /*.primary-button {
            margin-left: 45px;

            i {
                font-size: 14.6px;
                font-weight: 400;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: -.4px;
                text-align: left;
                color: $clarke-white;
                margin-left: 45px;
            }
        }*/

        /*.secondary-button {
            i {
                font-size: 14.6px;
                font-weight: 400;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.29;
                letter-spacing: -.4px;
                text-align: left;
                color: $clarke-white;
                margin-left: 45px;
            }
        }*/
    }

    .secondary-navigation {

        /*height: 160px;
        background: $clarke-black;

        .nav-button {
            height: 140px;
            background-image: linear-gradient(rgba(0,0,0,.65),rgba(0,0,0,.65)),url('/img/cogs.jpg');
            margin-top: 10px;

            &:last-child {
                margin-right: 0;
            }

            .title {
                font-family: $font-stack-open-sans;
                font-size: 16.7px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 3.0;
                letter-spacing: -0.5px;
                text-align: left;
                color: $clarke-white;
                padding: 15px 100px 0 100px;
            }

            i {
                font-size: 50px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.0;
                letter-spacing: -1.4px;
                text-align: left;
                color: $clarke-white;
                padding: 5px 110px;
            }
        }*/
    }

    .reference-background {

        /*background: #e2dddd;
        height: 400px;
        padding-bottom: 10px;
        border-bottom: 10px solid $clarke-black;

        .reference-tools {
            margin: 30px 50px;
        }

        .reference-tools-inner {
            background: $clarke-white;
            padding: 20px 30px;

            .title {
                font-family: $font-stack-open-sans;
                font-size: 25px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.7px;
                text-align: left;
                color: #272727;
                margin-bottom: 15px;
            }

            .text p {
                font-family: $font-stack-open-sans;
                font-size: 14.6px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.4px;
                text-align: left;
                color: #303030;
                margin-bottom: 15px;
            }

            .input {
                padding-left: 0;

                input {
                    height: 35px;
                    font-family: $font-stack-open-sans;
                    font-size: 12px;
                }
            }

            .search-button {
                padding-left: 0;

                a {
                    @extend .primary-button;
                    padding: 10px 20px 10px 13px;
                    color: $clarke-white;
                }
            }

            ul {
                color: $clarke-red;
            }

            li {
                text-indent: -5px;
            }

            a {
                font-family: $font-stack-open-sans;
                font-size: 14.6px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: -0.4px;
                text-align: left;
                color: $clarke-red;
            }
        }

        .heading {
            font-family: $font-stack-open-sans;
            font-size: 16.7px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: 3.0;
            letter-spacing: -0.5px;
            text-align: left;
            color: $clarke-red;
            text-transform: uppercase;
            margin-bottom: 10px;

            i {
                margin-left: 15px;
                font-size: 25px;
                line-height: 2.0;
                letter-spacing: -0.7px;
                color: #2a2a2a;
            }
        }*/
    }
    
    .news-background {
        /*background: #e2dddd;
        height: 420px;
        padding-bottom: 10px;
        border-bottom: 10px solid $clarke-black;

        .news {
            margin: 35px 50px;

            .news-title {
                font-family: $font-stack-open-sans;
                font-size: 16.7px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 3;
                letter-spacing: -0.5px;
                text-align: left;
                color: $clarke-red;
                text-transform: uppercase;
            }

            .date {
                font-family: $font-stack-open-sans;
                font-size: 12.5px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 4;
                letter-spacing: -0.3px;
                text-align: left;
                color: #282828;
            }

            .title {
                font-family: $font-stack-open-sans;
                font-size: 25px;
                font-weight: 300;
                font-style: normal;
                font-stretch: normal;
                line-height: normal;
                letter-spacing: -0.7px;
                text-align: left;
                color: $clarke-red;
                margin-bottom: 18px;
            }

            .text {
                p {
                    color: #303030;
                    line-height: normal;
                    margin-bottom: 30px;
                }
            }

            article {
                background: $clarke-white;
                padding: 20px 30px;
            }

            a {
                font-family: $font-stack-open-sans;
                font-size: 14.6px;
                font-weight: bold;
                font-style: normal;
                font-stretch: normal;
                line-height: 1.43;
                letter-spacing: -0.4px;
                text-align: left;
                color: $clarke-red;
            }
        }*/
    }

/*~^~.~^~.~^~*/
.hero-text {
    background: rgba(0, 0, 0, .7);
    padding-left: 140px;
    padding-top: 30px;
    width: 750px;
    padding-bottom: 30px;
    position: absolute;
    top: 60px;
    left: 0;

    @media screen and (max-width: 768px) {
        top:0;
        width:100%;
        padding-left:0;
    }
}
.home-page-container .hero .orbit.hero-slides ul.orbit-container li.orbit-slide h2 {
    background: unset;

    @media screen and (max-width: 768px) {
        font-size:18px;
    }
    }

    .home-page-container .hero .orbit.hero-slides ul.orbit-container li.orbit-slide h1 {
        font-size: 64px;
        line-height: 1;
        text-shadow: 4px 4px 4px black;
        background: unset;
        font-family: "Roboto", sans-serif;
        font-weight: 500;

        @media screen and (max-width: 768px) {
            font-size: 40px;
            padding-bottom: 0;
        }
    }

    .home-page-container .hero .orbit.hero-slides ul.orbit-container li.orbit-slide .orbit-hero-slide-headline {
        padding-top: 0;
    }

    @media screen and (max-width: 768px) {
    }

