﻿@import "_variables.scss";


#container {
    &.PowerCurve {
        font-family: Verdana;
        overflow: hidden;
        margin: 0 auto;
        /*border: 1px solid grey;
        width: 950px;*/
        background: white;
        

        span {
            /*display: block;*/
            margin-top: 0;
            margin-bottom: 0;
        }

        img {
            vertical-align: initial;
            float: none;
        }
    }

    #yAxisLabel {
        color: black;
        font-style: italic;
        font-weight: 500;
        font-size: 15px;
        -webkit-transform: rotate(270deg);
        -moz-transform: rotate(270deg);
        -o-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
        transform: rotate(270deg);
        white-space: nowrap;
        margin-top: 30%;
    }
}
