﻿$item-display-duration: 5 !default; //seconds
$transition-duration: 2 !default; // seconds
$display-duration: $transition-duration + $item-display-duration;

$gallery-inactive-color: #ccc !default;
$gallery-inactive-transparent-color: white !default;
$gallery-inactive-opacity: 0.4 !default;
$gallery-active-color: white !default;
$gallery-active-transparent-color: white !default;
$gallery-active-opacity: 0.8 !default;

@mixin inactive-button {
  color: $gallery-inactive-color;
  color: rgba($gallery-inactive-transparent-color, $gallery-inactive-opacity);
}

@mixin active-button {
  color: $gallery-active-color;
  color: rgba($gallery-active-transparent-color, $gallery-active-opacity);
}

%inactive-button {
  @include inactive-button;
}

%active-button {
  @include active-button;
}