﻿/********************/
/***** GLOBALS *****/
/******************/

$font-stack-open-sans: 'Open Sans', sans-serif;
$font-stack-montserrat: 'Montserrat', 'Open Sans', sans-serif;

$clarke-black:     rgb(  0,  0,  0); /*| Tank Guideline PMS: NONE  :: Rich Black R:  0 G:  0 B:  0  |*/
$clarke-white:     rgb(255,255,255); /*| Tank Guideline PMS: NONE  :: White      R:255 G:255 B:255  |*/
$clarke-canvas:    #f7f7f7;
$clarke-red:       rgb(218, 41, 28); /*| Tank Guideline PMS: 485 C :: https://www.pantone.com/color-finder/485-C |*/
$clarke-red-65:    #DF7371;          
$clarke-red-45:    #E7A3A1;
$clarke-red-25:    #F1CECD;
$clarke-red-hover: #df7371; /*#ff4d36;*/
$clarke-dark-gray: #29343C;
$clarke-gray:      rgb( 51, 63, 72); /*| Tank Guideline PMS: 432 C :: https://www.pantone.com/color-finder/432-C |*/
$clarke-gray-65:   #79858f; /*#797C80;*/
$clarke-gray-45:   #a5b2bc; /*#A5A7A9;*/
$clarke-gray-25:   #cedbe6; /*#CECFD0;*/
$clarke-gray-05:   #efefef;


/*$clarke-red:#EE2E24;*/ /*JDK Artwork*/