﻿@import "_variables.scss";

/*****************************************/
/***** HEADERS & PRIMARY NAVIGATION *****/
/***************************************/

/**** UTILITY HEADER ****/
.utility-bar-container {background:$clarke-gray;/*#4f4f4f;*/
    .utility-bar {position:relative;background:$clarke-gray;/*#4f4f4f;*/padding:0 40px 0;
        ul {float:right;margin: 0 0 3px 0;
            &.dropdown.menu {display:inline-block;}
        }
        li {margin-left: 26px;display: inline-block;}
        a {color:$clarke-white;text-transform: uppercase;font:{family:$font-stack-open-sans;size:10.4px;weight:700;}letter-spacing: -0.2px;transition: all 0.5s ease-in-out 0s;padding:0;
           i {font-size: 16.7px;vertical-align: middle;transition: all 0.5s ease-in-out 0s;}
        }
        a:link, a:visited {color:$clarke-white;}
        a:hover, a:active {color:$clarke-red;}     
        
        ul.is-dropdown-submenu {
            z-index:1000;
            border:0px none;
            background-color:$clarke-gray;
            font:{family:$font-stack-open-sans;size:12.5px;weight:700;}letter-spacing: -0.2px;
            padding:20px 10px;
            margin:0;
            li {
                margin:0;
                color:$clarke-white;
                a {
                    font:{family:$font-stack-open-sans;size:10.4px;weight:500;}letter-spacing: -0.2px;
                    display: inline-block;
                    padding: 0px;
                    line-height: 16.6333px;                
                    transition: all 0.5s ease-in-out 0s;
                }
                a:link, a:visited {color:$clarke-white;text-decoration:none;}
                a:hover, a:active {color:$clarke-red;text-decoration:none;}
            }
        }
        .dropdown.menu > li.is-dropdown-submenu-parent > a {padding-right:0;}
        .dropdown.menu > li.is-dropdown-submenu-parent > a::after {content:none;}
    }
}

/*****************/
/* HEADER COLORS */
/******************
/* Clarke initially went back and forth on a "white" background or "red" background header, and various combinations for full-width vs. mobile (originally white and red respectively).
/*   It was settled upon during design conversion approval to be consistent in color between full-width and mobile and to accept Tank and TDF recommendations of a brand-consistent White header.
/* During the build UAT phase, Clarke again requested to see a "red" variant of the header and processed a change request to do so.
/*   Below you will find comments of "RED" and "WHITE" for critical CSS classes. To flip the header's colors to either, uncomment the desired option and comment out the other.
/*   You must also change out the header logo image in the design templates to be either white or red as necessary in Sitefinity after the CSS color changes push out to the environment.
******************/

/**** MAIN HEADER (STICKY) ****/
/*** WHITE FULL WIDTH (LARGE AND GREATER) ***/
.white-header-container { border-bottom: 10px solid $clarke-black; /*#161616;*//*top: -10px;*/z-index: 100;
    /*WHITE*/ /*background:$clarke-white;*/
    /*RED*/ background:$clarke-red;
    .white-header {overflow:hidden;height:93px;min-height:83px;max-height:93px;padding:0 40px 0;
        /*WHITE*/ /*background:$clarke-white url('/img/header-white.jpg') no-repeat top left;*/
        /*RED*/ background:$clarke-red url('/img/header-red.jpg') no-repeat top left; background-size:cover;
        .logo {padding-top:27px;/*24px;*/
            img {
                vertical-align:unset;
                width:100%;
            }
            a {
                display:block;
                position:relative;
                /*max-height:34px;*/
                /*max-width:235px;*/
                width:300px;
            }
            .tagline {display:block;position:relative;padding-top:5px;/*max-width:218px;*/font: {family:'Montserrat';size:9.8px;weight: bold;}letter-spacing: -0.5px;text-transform: uppercase;
                /*WHITE*/ /*color: $clarke-red;*/
                /*RED*/ color: $clarke-white;
            }    
        }
        .actions {padding:0;
            .header-navigation {display:block;position:relative;float:right;

                ul {
                    list-style-type: none;
                }

                li {float:left;padding:57px 0.375rem 19px 0.375rem;transition: all 0.5s ease-in-out 0s;
                    a {display:inline-block;position:relative;font: {family:$font-stack-open-sans;size: 14.6px;weight: 700;}letter-spacing: -0.3px;text-align: left;text-transform: uppercase;transition: all 0.5s ease-in-out 0s;
                        /*WHITE*/ /*color: $clarke-red;*/
                        /*RED*/ color: $clarke-white;
                        i {font-size: 18.8px;transition: all 0.5s ease-in-out 0s;}
                    }
                    a:link, a:visited {background-color:transparent;
                        /*WHITE*/ /*color: $clarke-red;*/
                        /*RED*/ color: $clarke-white;
                    }
                    a:hover, a:active, a.selected {/*background-color:$clarke-red;*/
                        /*WHITE*/ /*color: $clarke-white;*/
                        /*RED*/ color: $clarke-red;
                        i {
                            /*WHITE*/ /*color: $clarke-white;*/
                            /*RED*/ color: $clarke-red;
                        }
                    }
                }
                li:hover, li.selected, li.main-tab.hover {
                    /*WHITE*/ /*background-color:$clarke-red;*/
                    /*RED*/ background-color:$clarke-white;
                    a, i {
                        /*WHITE*/ /*color: $clarke-white;*/
                        /*RED*/ color: $clarke-red;
                    }
                }
                
            }   
            .header-search, .header-language {display:block;position:relative;float:right;padding:57px 0.9375rem 15px 0.9375rem;transition: all 0.5s ease-in-out 0s;background-color:transparent;cursor:pointer;
                .fa {transition: all 0.5s ease-in-out 0s;
                     /*WHITE*/ /*color: $clarke-red;*/
                     /*RED*/ color: $clarke-white;
                }
            }
            .header-search:hover, .header-search.selected, .header-language:hover, .header-language.selected {
                /*WHITE*/ /*background-color:$clarke-red;*/
                /*RED*/ background-color:$clarke-white;
                .fa {
                    /*WHITE*/ /*color: $clarke-white;*/
                    /*RED*/ color: $clarke-red;
                }
            }            
        }
    }
    .menu-dropdowns {
        /*.dropdown-pane {transition: all 0.5s ease-in-out 0s;}*/

        .dropdown-pane.languages-panel, .dropdown-pane.search-panel, .dropdown-pane.menu-panel {
            // Global background setting for all wide menu drop panels.
            // Color uses alpha-blend transparency via an RGBA directive. The last value controls opacity of the background color. 
            //     1.0 = Solid, 0.0 = Transparent; use this value to control the drop panel's (including "Mega Menu") tranpsarency.
            background:rgba(255,255,255,0.95);
        }

        .dropdown-pane.languages-panel {top:unset !important;left:unset !important;right:0 !important;width:260px !important;padding:35px 0 35px 10px;
            ul {list-style:none;}
            li {padding-bottom:5px;}
            li, li a {font-family:$font-stack-open-sans;font-size:14.58px;color:$clarke-red;}
        }
        .dropdown-pane.search-panel, .dropdown-pane.menu-panel {top:unset !important;left:unset !important;width:100% !important;}
        .languages-panel {/*box-shadow: -10px 10px 10px;*/ /*border-top:3px solid $clarke-red;border-left:3px solid $clarke-black;border-bottom:10px solid $clarke-black;*/}
        .search-panel {/*box-shadow: 0px 10px 10px;*/ /*border-top:3px solid $clarke-red;border-bottom:10px solid $clarke-black;*/
            padding:30px 50px;
            
            .sp-wrapper {margin:0 auto; max-width:1024px;}

            input[type="search"], input[type="text"] {
                float:left;
                margin:0;
                background-color:$clarke-white; 
                padding:0 0.5em;               
                border:solid 2px $clarke-gray-25;      
                box-shadow:none;
                width:90%;
                font-size:30.00px;
                color:$clarke-dark-gray;
                
            }
            input[type="button"], button, input[type="button"].primary-button, button.primary-button {
                float:right;
                transition:none;
                margin:0;
            }
        }
        .dropdown-pane.menu-panel {

            padding:30px 40px;
            
            h3 {
                font:{family:$font-stack-open-sans;size:30.00px}color:$clarke-black;
                a {
                    &:link, &:visited {color:$clarke-black;}
                    &:hover, &:active {color:$clarke-red;}
                }
            }

            ul.mm-list {
                list-style:none;margin:0;padding:0;
                li, li a {font-family:$font-stack-open-sans;font-size:14.58px;}
                li {color:$clarke-black;}
                li a, li a:link, li a:visited {color:$clarke-red;}
                li a:hover, li a:active {color:$clarke-red-hover;}
            }

            .product-quick-search {
                .product-quick-search-inner {
                    .advanced-product-search-widget {
                        .pqs-pop {
                            legend {font:{family:$font-stack-open-sans;size:13.58px;weight:400;}color:$clarke-black;}
                            label {font:{family:$font-stack-open-sans;size:13.58px;weight:400;}color:$clarke-black;margin-right:0.5rem;}
                        }
                        .pqs-type {
                            label {font:{family:$font-stack-open-sans;size:13.58px;weight:400;}color:$clarke-black;}
                            select {padding:0;margin:5px 0 0 0;height:auto;background:$clarke-white;border:1px solid $clarke-black;color:$clarke-black;}
                        }
                        .pqs-emissions {
                            label {font:{family:$font-stack-open-sans;size:13.58px;weight:400;}color:$clarke-black;}
                            select {padding:0;margin:5px 0 0 0;height:auto;background:$clarke-white;border:1px solid $clarke-black;color:$clarke-black;}
                        }
                        .pqs-sliders {
                            padding-top:20px;
                        }

                        .slider-container {
                            padding:{top:20px;bottom:20px;}
                            .slider-label {}
                            .slider-min {}
                            .slider-max {}
                            .slider-input {
                                width:75%;
                                .slider {
                                    .slider-handle {background-color:$clarke-red;
                                        input[type="text"] {background-color:$clarke-red;color:$clarke-white;}
                                    }
                                    .slider-fill {background-color:$clarke-red;}
                                }
                            }
                        }
                        .slider-container.slider-rpm {

                        }
                        .slider-container.slider-power {
                            padding-bottom:30px;
                        }
                        .pqs-search-input {
                            input[type="text"] {}
                        }
                        .pqs-search-button {
                            &.go-by-form {
                                padding-top:95px;
                            }
                        }

                        .pqs-kwmn {
                            .pqs-search-input, .pqs-search-button {
                                padding-top:10px;
                            }
                        }
                    }
                }
            }
            
        }
        .dropdown-pane.menu-panel.row.expanded {
            max-width:1920px;
            right:0 !important;
        }
    }
}
/*** MOBILE HEADER (MEDIUM AND SMALL) ***/
.red-header-container {
    /*WHITE*/ /*background:{color:$clarke-white;repeat:no-repeat;position-x:left;position-y:top;}*/
    /*RED*/background:{color:$clarke-red;repeat:no-repeat;position-x:left;position-y:top;}
    border-top-color:$clarke-gray;
    border-top-style:solid;
    border-bottom-color:$clarke-black;
    border-bottom-style:solid;
    padding:0 18px 0;
    z-index: 100;
    .red-header {z-index: 101;
        .logo {z-index:102;
            img {vertical-align:unset;}
            a {display:block;position:relative;}
            .tagline {display:block;position:relative;padding-top:5px;max-width:218px;font:{family:'Montserrat';size:9.8px;weight: bold;}letter-spacing: -0.5px;text-transform: uppercase;
                /*WHITE*/ /*color: $clarke-red;*/
                /*RED*/ color: $clarke-white;
            }
        }
        .actions { z-index:102;
            .fa {transition: all 0.5s ease-in-out 0s;
                /*WHITE*/ /*color: $clarke-red;*/
                /*RED*/ color: $clarke-white;
            }
            a {font: {family:$font-stack-open-sans;size: 14.6px;weight: 700;}color:$clarke-gray;letter-spacing: -0.3px;text-align: left;text-transform: uppercase;transition: all 0.5s ease-in-out 0s;}
            .mobile-navigation {display:block;position:relative;float:right;z-index:103;
                span.fa-bars {display:inline;}
                span.fa-close {display:none;}
            }
            .mobile-navigation.hover {
                span.fa-bars {display:none;}
                span.fa-close {display:inline;}
            }
            .mobile-navigation:hover {cursor:pointer;}
            .mobile-language {display:block;position:relative;float:right;height:71px;z-index:103;}
            .mobile-language:hover {cursor:pointer;}
            
        }
    }
    .mobile-dropdowns {
        .mobile-languages-panel {
            background-color:$clarke-white;
            position:relative;
            padding:30px;
            border-bottom:10px solid $clarke-black;
            ul {list-style:none;margin:0;padding:0;}
            li {padding-bottom:5px;}
            li, li a {font-family:$font-stack-open-sans;font-size:25.00px;color:$clarke-red;}
        }
        .mobile-navigation-panel {
            /*position:relative;*/
            background:#e0dcdc;/*$clarke-gray-25;*/
            left: 0 !important;
            border-bottom:10px solid $clarke-black;
            .mnp-search {
                padding:10px 30px;
                background-color:$clarke-white;

                .mnps-wrapper {margin:0 auto; max-width:1024px;}

                input[type="search"], input[type="text"] {
                    float:left;
                    margin:0;
                    background-color:$clarke-white;
                    border:none 0px transparent;      
                    box-shadow:none;
                }
                input[type="button"], button, input[type="button"].primary-button, button.primary-button {
                    float:right;
                    transition:none;
                    margin:0;
                }                 
            }
            .mnp-nav {
                padding:0 0 30px 0;
            }
        }
        .row.expanded {
            width:100%;
        }
    }
    .mobile-dropdowns.row {padding:0;}
    
    .accordion-nav {display:block;
        .an {display:block;margin:0;padding:0;list-style-type:none;
            .an-item {display:block;border-bottom:solid 1px $clarke-gray-05;
                .ani-tab {display:block;
                    .ani-trigger {display:block;cursor:pointer;float:left;width:80px;padding:8px 0 0 50px;
                        i {font-size:16.67px;}
                        .fa-plus-circle {display:none;}
                        .fa-minus-circle {display:none;}
                    }
                    .ani-link {display:block;float:left;
                        font-size:25px;
                        a {font-size:25px;}
                    }
                }
                .ani-panel {
                    background:$clarke-gray-05;
                    ul {
                        margin:0;padding:0;list-style-type:none;
                    }
                    li {
                        border-bottom:solid 1px #e0dcdc;
                        padding:5px 50px;
                    }
                    li:last-child {border-bottom:none 0px;}
                    li, li a {font-family:$font-stack-open-sans;font-size:18.75px;}
                }                
            }
            
            .an-item.sub-item, .an-item .an-item.sub-item {
                border-bottom:none 0px;
                .ani-tab { width:100%;
                    .ani-trigger {
                        display:block;
                        position:relative;
                        width:15%;
                        min-width:50px;
                        max-width:80px;
                        i {color:$clarke-red;}
                        .fa-plus-circle {display:inline;}
                        .fa-minus-circle {display:none;}
                    }
                    .ani-link {/*color:$clarke-black;*/
                        display:block;
                        position:relative;
                        width:75%;
                        a {color:$clarke-red;}
                    }
                    
                }
                .ani-panel {                   
                    li {
                        border-bottom:none 0 Transparent;
                    }
                }      
            }

            .an-item.expand {
                .ani-tab {
                    .ani-trigger {
                        i {color:$clarke-red;}
                        .fa-plus-circle {display:inline;}
                        .fa-minus-circle {display:none;}
                    }
                    .ani-link {/*color:$clarke-black;*/
                        a {color:$clarke-red;}
                    }
                    
                }
                &.sub-item {
                    .ani-tab {
                        .ani-trigger {
                            i {color:$clarke-red;}
                            .fa-plus-circle {display:inline;}
                            .fa-minus-circle {display:none;}
                        }
                        .ani-link {/*color:$clarke-black;*/
                            a {color:$clarke-red;}
                        }
                    
                    }
                }
            }
            .an-item.collapse { /*background-color:$clarke-red;*/
                .ani-tab {
                    .ani-trigger {
                        i {color:$clarke-red;}
                        .fa-plus-circle {display:none;}
                        .fa-minus-circle {display:inline;}
                    }
                    .ani-link {/*color:$clarke-black;*/
                        a {/*color:$clarke-white;*/}
                    }
                }
                &.sub-item { background-color:$clarke-gray-05;
                    .ani-tab {
                        .ani-trigger {
                            i {color:$clarke-red;}
                            .fa-plus-circle {display:none;}
                            .fa-minus-circle {display:inline;}
                        }
                        .ani-link {/*color:$clarke-gray-65;*/
                            a {color:$clarke-red;}
                        }
                    }
                }
            }            

            .an-item:last-child {border-bottom:none 0px;}
        }
    }

}
@media screen and (min-width:1921px) {
    .white-header-container .white-header .actions .header-navigation li {padding:{left:1.9375rem;right:1.9375rem;}}
}
@media screen and (min-width:1440px) and (max-width:1920px) {
    .white-header-container .white-header .actions .header-navigation li {padding:{left:1.6000rem;right:1.6000rem;}}
}
@media screen and (min-width:1025px) and (max-width:1440px) {
    .product-quick-search {
                .product-quick-search-inner {
                    .advanced-product-search-widget {
                        .slider-container {
                            .slider-input {
                                width:60%;
                            }
                        }
                    }
                }
            }
}
@media screen and (min-width:1081px) and (max-width:1439px) {
    .white-header-container .white-header .actions .header-navigation li {padding:{left:0.9375rem;right:0.9375rem;}}
}
@media screen and (min-width:1025px) and (max-width:1080px) {
    .white-header-container .white-header .actions .header-navigation li {padding:{left:0.6000rem;right:0.6000rem;}}
}
@media screen and (min-width:640px) and (max-width:1024px) {
    .red-header-container {
        height:110px;max-height:110px;min-height:110px;
        /*background-image:url('/img/header-red-1024.jpg');*/
        border-top-width:8px;
        border-bottom-width:10px;
        .red-header {
            .logo {padding-top:30px;/*20px;*/
                a {max-height:34px;max-width:235px;}
            }
            .actions {padding-top:15px;
                .fa {font-size:33.33px;}
                .mobile-navigation {padding-left:15px;height:71px;width:45px;}
                .mobile-language {height:71px;}
            }
        }
        .mobile-dropdowns {
            .mobile-navigation-panel {
                .mnp-search {
                    input[type="search"], input[type="text"] {width:85%;}
                    .mnps-wrapper {width:100%;}
                }
            }
        }
    }
}
@media screen and (max-width:685px) {
    .red-header-container {
        .accordion-nav {
            .an {
                .an-item {
                    .ani-tab {
                        .ani-trigger {
                            padding:8px 0 0 20px;
                            /*width:50px;*/
                            max-width:50px;
                        }
                    }
                    .ani-panel li {
                        padding:5px 25px;
                    }
                    &.sub-item .ani-tab .ani-trigger, .an-item.sub-item .ani-tab  .ani-trigger {
                        max-width:50px;
                        width:50px;
                        min-width:50px;
                    }

                    &.sub-item {
                        .ani-panel li {
                            padding-right:0px;
                        }       
                        &.deep-levels {
                            .ani-tab {
                                .ani-trigger {
                                    padding-top:0px;
                                }
                                .ani-link {
                                    padding-top:4px;
                                }
                                .ani-link, .ani-link a {
                                    font-size:16px;
                                    line-height:1.5rem;
                                }
                            } 
                        }
                        &.level-3 {
                            .ani-tab .ani-link {width:70%;}
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width:639px) {
    .red-header-container {
        height:78px;max-height:78px;min-height:78px;
        /*background-image:url('/img/header-red-0640.jpg');*/
        border-top-width:6px;
        border-bottom-width:10px;
        .red-header {
            .logo {padding-top:18px;/*12px;*/
                a {max-height:27px;max-width:191px;}
                .tagline {padding-top:3px;max-width:191px;font:{size:8.2px;}}
            }
            .actions {padding-top:5px;
                .fa {font-size:25.00px;}
                .mobile-navigation {padding-left:15px;height:54px;width:38px;}
                .mobile-language {height:54px;}
            }
        }
        .mobile-dropdowns {
            .mobile-navigation-panel {
                .mnp-search {
                    input[type="search"], input[type="text"] {width:75%;}
                    .mnps-wrapper {width:100%;}
                }
            }
        }
    }
}
@media screen and (max-width:480px) {
    .red-header-container {
        .mobile-dropdowns {
            .mobile-navigation-panel {
                .mnp-search {
                    input[type="search"], input[type="text"] {width:62%;}
                    .mnps-wrapper {width:100%;}
                }
            }
        }
    }
}
@media screen and (max-width:370px) {
    .red-header-container {        
        .red-header {
            .logo {padding-top:22px;
                a {max-height:unset;max-width:147px;}
                .tagline {display:none;}
            }
            .actions {padding-top:10px;
                .fa {font-size:20.00px;}
                .mobile-navigation {padding-left:10px;height:49px;width:30px;}
                .mobile-language {height:49px;}
            }
        }
        .mobile-dropdowns {
            .mobile-navigation-panel {
                .mnp-search {
                    input[type="search"], input[type="text"] {width:62%;}
                    .mnps-wrapper {width:225px;}
                }
            }
        }
    }
}
/*~^~.~^~.~^~*/