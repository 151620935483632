﻿@import "_variables.scss";

/***********************************/
/***** USER REGISTRATION FORM *****/
/*********************************/
/*.reg-form h4 {padding:0 0.9375rem;margin-bottom:8px;}
.reg-form p {padding:0 0.9375rem;}*/
.content-container {
    .stepper {
        margin-top: 20px;
    }
}

.reg-form {
    .reg-radio label {
        display: inline;
        position:relative;
    }

    select {
        background: none;
        background-color: $clarke-white;
    }

    div.validation-summary-errors, .radio-validation-error {
        font-weight: 700;
        color: $clarke-red;
    }

    .input-validation-error {
        background-color: $clarke-red-45;
        &::placeholder {
            color: $clarke-black;
        }
    }   
}

.reg-control {
    margin: 40px 0 0 0;
}

.columns.reg-ctl-right {
    float: right;
    text-align: right;
}

.columns.reg-ctl-left {
    float: left;
    text-align: left;
}

.account-support-nav h4 {
    color: $clarke-red;
}

.account-support-nav ul {
    color: $clarke-red;
}

.account-support-nav li, .account-support-nav li a {
    color: $clarke-red;
    font-weight: 700;
}

.columns.step-visual-indicator {
    float: right;
}

.columns.step-title {
    float: left;
}
/*~^~.~^~.~^~*/
