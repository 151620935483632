﻿@import "_variables.scss";


/******************************/
/***** PUMP ROOM FEATURE *****/
/****************************/
.pump-room.content-container {
    .main-content-container {padding:0; .main-content { .page-information {padding:{left:40px;right:40px;}} } }
    .pump-room-content {
        .columns {padding:{left:55px;right:55px;}margin:0;}
        .columns.pump-room-content-wrapper {padding:{left:0;right:0;}margin:0;}
        .pump-room-interactive {display:block;position:relative;width:auto;height:auto;padding:0;margin:0 auto 50px;background:{color:$clarke-gray;repeat:no-repeat;position:0 0;size:contain;}
            .pr-hot-spot {display:block;position:absolute;text-align:center;cursor:pointer; span, i {display:inline-block;position:relative;background-color:$clarke-white;border-radius:50%;color:$clarke-red;} }
            .pr-hot-spot-panel {width:250px;height:auto;padding:20px; h5 {font:{size:16.67px;weight:400;}color:$clarke-dark-gray;} p  {font:{size:15.85px;weight:300;}color:$clarke-dark-gray;} }
        }
        .pump-room-parts {.prp-part { h5 a {@extend .primary-button;} .prpp-details {display:none;transition: all 0.5s ease-in-out 0s; .prppd-button {@extend .primary-button;} } } }
    }    
}

@media screen and (min-width:2580px) {
    
    .pump-room.content-container { .pump-room-content.prc-two-col {
        .pump-room-parts {text-align:center; .prp-part {display:inline-block;float:none;margin:0 25px 10px 25px;}}
        .pump-room-interactive {background-image:url('/img/pump-room-1920.jpg');width:1920px;max-width:1920px;min-width:1920px;height:1080px;max-height:1080px;min-height:1080px;
            .pr-hot-spot { width:35px;height:35px; i, span {width:34px;height:35px;padding:4px;font-size:30px;}}
            .pr-hot-spot.prhs-01 {top:420px;left: 420px;}
            /*.pr-hot-spot.prhs-02 {top:420px;left: 755px;}*/
            .pr-hot-spot.prhs-02 {top:420px;left:1040px;}
            .pr-hot-spot.prhs-03 {top:445px;left: 875px;}
            .pr-hot-spot.prhs-04 {top:260px;left: 940px;}
            .pr-hot-spot.prhs-05 {top:471px;left: 991px;}
            .pr-hot-spot.prhs-06 {top:160px;left:1530px;}
            .pr-hot-spot.prhs-07 {top:520px;left:1525px;}
            .pr-hot-spot.prhs-08 {top:545px;left:1640px;}
    } } }
}

@media screen and (min-width:2150px) and (max-width:2549px) {
    .pump-room.content-container { .pump-room-content.prc-two-col {
        .pump-room-parts {text-align:center;.prp-part {display:inline-block;float:none;margin:0 15px 10px 15px;} }
        .pump-room-interactive {background-image:url('/img/pump-room-1600.jpg');width:1600px;max-width:1600px;min-width:1600px;height:900px;max-height:900px;min-height:900px;
            .pr-hot-spot { width:30px;height:30px; i, span {width:29px;height:30px;padding:4px;font-size:24px;}}
            .pr-hot-spot.prhs-01 {top:350px;left: 350px;}
            /*.pr-hot-spot.prhs-02 {top:350px;left: 630px;}*/
            .pr-hot-spot.prhs-02 {top:350px;left: 875px;}
            .pr-hot-spot.prhs-03 {top:370px;left: 730px;}
            .pr-hot-spot.prhs-04 {top:210px;left: 780px;}
            .pr-hot-spot.prhs-05 {top:391px;left: 831px;}
            .pr-hot-spot.prhs-06 {top:130px;left:1270px;}
            .pr-hot-spot.prhs-07 {top:435px;left:1265px;}
            .pr-hot-spot.prhs-08 {top:455px;left:1360px;}
        } 
    } }
}

@media screen and (min-width:1921px) and (max-width:2149px) {
    
    .pump-room.content-container { .pump-room-content.prc-two-col { 
        .pump-room-parts {padding:{left:55px;right:55px;} .prp-part {float:left;margin:0 10px 10px 0;} }
        .pump-room-interactive {background-image:url('/img/pump-room-1200.jpg');width:1200px;max-width:1200px;min-width:1200px;height:675px;max-height:675px;min-height:675px;
            .pr-hot-spot { width:26px;height:26px; i, span {width:25px;height:26px;padding:4px;font-size:20px;}}
            .pr-hot-spot.prhs-01 {top:260px;left: 265px;}
            /*.pr-hot-spot.prhs-02 {top:255px;left: 470px;}*/
            .pr-hot-spot.prhs-02 {top:255px;left: 655px;}
            .pr-hot-spot.prhs-03 {top:270px;left: 545px;}
            .pr-hot-spot.prhs-04 {top:150px;left: 580px;}
            .pr-hot-spot.prhs-05 {top:291px;left: 621px;}
            .pr-hot-spot.prhs-06 {top:100px;left: 950px;}
            .pr-hot-spot.prhs-07 {top:320px;left: 950px;}
            .pr-hot-spot.prhs-08 {top:350px;left:1020px;}
        } 
    } }

    
}
@media screen and (min-width:1921px) {
    .pump-room.content-container { .pump-room-content.prc-full {
        .pump-room-parts {text-align:center; .prp-part {display:inline-block;float:none;margin:0 25px 10px 25px;}}
        .pump-room-interactive {background-image:url('/img/pump-room-1920.jpg');width:1920px;max-width:1920px;min-width:1920px;height:1080px;max-height:1080px;min-height:1080px;
            .pr-hot-spot { width:35px;height:35px; i, span {width:34px;height:35px;padding:4px;font-size:30px;}}
            .pr-hot-spot.prhs-01 {top:420px;left: 420px;}
            /*.pr-hot-spot.prhs-02 {top:420px;left: 755px;}*/
            .pr-hot-spot.prhs-02 {top:420px;left:1040px;}
            .pr-hot-spot.prhs-03 {top:445px;left: 875px;}
            .pr-hot-spot.prhs-04 {top:260px;left: 940px;}
            .pr-hot-spot.prhs-05 {top:471px;left: 991px;}
            .pr-hot-spot.prhs-06 {top:160px;left:1530px;}
            .pr-hot-spot.prhs-07 {top:520px;left:1525px;}
            .pr-hot-spot.prhs-08 {top:545px;left:1640px;}
        } 
    } }
}

@media screen and (min-width:1601px) and (max-width:1920px) {
    .pump-room.content-container { .pump-room-content.prc-full {
        .pump-room-parts {text-align:center;.prp-part {display:inline-block;float:none;margin:0 15px 10px 15px;} }
        .pump-room-interactive {background-image:url('/img/pump-room-1600.jpg');width:1600px;max-width:1600px;min-width:1600px;height:900px;max-height:900px;min-height:900px;
            .pr-hot-spot { width:30px;height:30px; i, span {width:29px;height:30px;padding:4px;font-size:24px;}}
            .pr-hot-spot.prhs-01 {top:350px;left: 350px;}
            /*.pr-hot-spot.prhs-02 {top:350px;left: 630px;}*/
            .pr-hot-spot.prhs-02 {top:350px;left: 875px;}
            .pr-hot-spot.prhs-03 {top:370px;left: 730px;}
            .pr-hot-spot.prhs-04 {top:210px;left: 780px;}
            .pr-hot-spot.prhs-05 {top:391px;left: 831px;}
            .pr-hot-spot.prhs-06 {top:130px;left:1270px;}
            .pr-hot-spot.prhs-07 {top:435px;left:1265px;}
            .pr-hot-spot.prhs-08 {top:455px;left:1360px;}
        } 
    } }
    .pump-room.content-container { .pump-room-content.prc-two-col { 
        .pump-room-parts {padding:{left:55px;right:55px;} .prp-part {float:left;margin:0 10px 10px 0;} }
        .pump-room-interactive {background-image:url('/img/pump-room-1200.jpg');width:1200px;max-width:1200px;min-width:1200px;height:675px;max-height:675px;min-height:675px;
            .pr-hot-spot { width:26px;height:26px; i, span {width:25px;height:26px;padding:4px;font-size:20px;}}
            .pr-hot-spot.prhs-01 {top:260px;left: 265px;}
            /*.pr-hot-spot.prhs-02 {top:255px;left: 470px;}*/
            .pr-hot-spot.prhs-02 {top:255px;left: 655px;}
            .pr-hot-spot.prhs-03 {top:270px;left: 545px;}
            .pr-hot-spot.prhs-04 {top:150px;left: 580px;}
            .pr-hot-spot.prhs-05 {top:291px;left: 621px;}
            .pr-hot-spot.prhs-06 {top:100px;left: 950px;}
            .pr-hot-spot.prhs-07 {top:320px;left: 950px;}
            .pr-hot-spot.prhs-08 {top:350px;left:1020px;}
        } 
    } }
}
@media screen and (min-width:1440px) and (max-width:1600px) {
    .pump-room.content-container { .pump-room-content.prc-full { 
        .pump-room-parts {text-align:center; .prp-part {display:inline-block;float:none;margin:0 5px 10px 5px;} }
        .pump-room-interactive {background-image:url('/img/pump-room-1440.jpg');width:1440px;max-width:1440px;min-width:1440px;height:810px;max-height:810px;min-height:810px;
            .pr-hot-spot { width:30px;height:30px; i, span {width:29px;height:30px;padding:4px;font-size:24px;}}
            .pr-hot-spot.prhs-01 {top:315px;left: 315px;}
            /*.pr-hot-spot.prhs-02 {top:310px;left: 565px;}*/
            .pr-hot-spot.prhs-02 {top:310px;left: 755px;}
            .pr-hot-spot.prhs-03 {top:325px;left: 655px;}
            .pr-hot-spot.prhs-04 {top:190px;left: 705px;}
            .pr-hot-spot.prhs-05 {top:351px;left: 741px;}
            .pr-hot-spot.prhs-06 {top:120px;left:1140px;}
            .pr-hot-spot.prhs-07 {top:385px;left:1145px;}
            .pr-hot-spot.prhs-08 {top:415px;left:1220px;}
        }
    } } 

    .pump-room.content-container { .pump-room-content.prc-two-col {
        .pump-room-parts {padding:{left:55px;right:55px;}.prp-part {float:left;margin:0 10px 10px 0;}}
        .pump-room-interactive {background-image:url('/img/pump-room-1024.jpg');width:1024px;max-width:1024px;min-width:1024px;height:576px;max-height:576px;min-height:576px;
            .pr-hot-spot { width:26px;height:26px; i, span {width:25px;height:26px;padding:4px;font-size:20px;}}
            .pr-hot-spot.prhs-01 {top:215px;left:220px;}
            /*.pr-hot-spot.prhs-02 {top:215px;left:400px;}*/
            .pr-hot-spot.prhs-02 {top:215px;left:550px;}
            .pr-hot-spot.prhs-03 {top:230px;left:465px;}
            .pr-hot-spot.prhs-04 {top:135px;left:500px;}
            .pr-hot-spot.prhs-05 {top:251px;left:521px;}
            .pr-hot-spot.prhs-06 {top: 80px;left:810px;}
            .pr-hot-spot.prhs-07 {top:270px;left:815px;}
            .pr-hot-spot.prhs-08 {top:300px;left:870px;}
        }
    } }

}
@media screen and (min-width:1201px) and (max-width:1439px) {
    .pump-room.content-container { .pump-room-content.prc-full { 
        .pump-room-parts {padding:{left:55px;right:55px;} .prp-part {float:left;margin:0 10px 10px 0;} }
        .pump-room-interactive {background-image:url('/img/pump-room-1200.jpg');width:1200px;max-width:1200px;min-width:1200px;height:675px;max-height:675px;min-height:675px;
            .pr-hot-spot { width:26px;height:26px; i, span {width:25px;height:26px;padding:4px;font-size:20px;}}
            .pr-hot-spot.prhs-01 {top:260px;left: 265px;}
            /*.pr-hot-spot.prhs-02 {top:255px;left: 470px;}*/
            .pr-hot-spot.prhs-02 {top:255px;left: 655px;}
            .pr-hot-spot.prhs-03 {top:270px;left: 545px;}
            .pr-hot-spot.prhs-04 {top:150px;left: 580px;}
            .pr-hot-spot.prhs-05 {top:291px;left: 621px;}
            .pr-hot-spot.prhs-06 {top:100px;left: 950px;}
            .pr-hot-spot.prhs-07 {top:320px;left: 950px;}
            .pr-hot-spot.prhs-08 {top:350px;left:1020px;}
        }
    } } 
    .pump-room.content-container { .pump-room-content.prc-two-col { .pump-room-parts {.prp-part { h5 {display:inline-block;margin:0;} h5 a {margin-bottom:0;} .prpp-details {display:none;} } } } }
    .pump-room.content-container { .pump-room-content.prc-two-col { 
        .pump-room-parts {padding:{left:30px;right:30px;} .prp-part {float:left;margin:0 10px 10px 0;} }
        .pump-room-interactive {background-image:url('/img/pump-room-0768.jpg');width:768px;max-width:768px;min-width:768px;height:432px;max-height:432px;min-height:432px;
            .pr-hot-spot { width:26px;height:26px; i, span {width:25px;height:26px;padding:4px;font-size:20px;}}
            .pr-hot-spot.prhs-01 {top:156px;left:163px;}
            /*.pr-hot-spot.prhs-02 {top:155px;left:295px;}*/
            .pr-hot-spot.prhs-02 {top:155px;left:415px;}
            .pr-hot-spot.prhs-03 {top:155px;left:344px;}
            .pr-hot-spot.prhs-04 {top: 90px;left:365px;}
            .pr-hot-spot.prhs-05 {top:191px;left:381px;}
            .pr-hot-spot.prhs-06 {top: 60px;left:600px;}
            .pr-hot-spot.prhs-07 {top:195px;left:600px;}
            .pr-hot-spot.prhs-08 {top:220px;left:660px;}
        } 
    } }
}
@media screen and (min-width:1025px) and (max-width:1200px) {
    .pump-room.content-container { .pump-room-content.prc-full {
        .pump-room-parts {padding:{left:55px;right:55px;}.prp-part {float:left;margin:0 10px 10px 0;}}
        .pump-room-interactive {background-image:url('/img/pump-room-1024.jpg');width:1024px;max-width:1024px;min-width:1024px;height:576px;max-height:576px;min-height:576px;
            .pr-hot-spot { width:26px;height:26px; i, span {width:25px;height:26px;padding:4px;font-size:20px;}}
            .pr-hot-spot.prhs-01 {top:215px;left:220px;}
            /*.pr-hot-spot.prhs-02 {top:215px;left:400px;}*/
            .pr-hot-spot.prhs-02 {top:215px;left:550px;}
            .pr-hot-spot.prhs-03 {top:230px;left:465px;}
            .pr-hot-spot.prhs-04 {top:135px;left:500px;}
            .pr-hot-spot.prhs-05 {top:251px;left:521px;}
            .pr-hot-spot.prhs-06 {top: 80px;left:810px;}
            .pr-hot-spot.prhs-07 {top:270px;left:815px;}
            .pr-hot-spot.prhs-08 {top:300px;left:870px;}
        }
    } } 
    .pump-room.content-container { .pump-room-content.prc-two-col { 
        .columns {padding:{left:35px;right:35px;}margin:0;} .columns.pump-room-content-wrapper {padding:{left:0;right:0;}margin:0;} .pump-room-parts {padding:{left:35px;right:35px;} .prp-part {float:left;margin:0 10px 10px 0; h5 {display:inline-block;margin:0;} h5 a {margin-bottom:0;} .prpp-details {display:none;} } }
        .pump-room-interactive {background-image:url('/img/pump-room-0640.jpg');width:640px;max-width:640px;min-width:640px;height:360px;max-height:360px;min-height:360px;} 
            .pr-hot-spot { width:20px;height:20px; i, span {width:19px;height:20px;padding:4px;font-size:16px;}}
            .pr-hot-spot.prhs-01 {top:133px;left:140px;}
            /*.pr-hot-spot.prhs-02 {top:129px;left:247px;}*/
            .pr-hot-spot.prhs-02 {top:129px;left:345px;}
            .pr-hot-spot.prhs-03 {top:139px;left:289px;}
            .pr-hot-spot.prhs-04 {top: 79px;left:311px;}
            .pr-hot-spot.prhs-05 {top:155px;left:328px;}
            .pr-hot-spot.prhs-06 {top: 50px;left:500px;}
            .pr-hot-spot.prhs-07 {top:168px;left:500px;}
            .pr-hot-spot.prhs-08 {top:187px;left:555px;}
    } }

}
@media screen and (min-width:1024px){
    .pump-room.content-container { .pump-room-content { .pump-room-parts {.prp-part { h5 {display:inline-block;margin:0;} h5 a {margin-bottom:0;} .prpp-details {display:none;} } } } }
}
@media screen and (min-width:768px) and (max-width:1024px) {  
    .pump-room.content-container { .pump-room-content.prc-full { 
        .pump-room-parts {padding:{left:30px;right:30px;} .prp-part {float:left;margin:0 10px 10px 0;} }
        .pump-room-interactive {background-image:url('/img/pump-room-0768.jpg');width:768px;max-width:768px;min-width:768px;height:432px;max-height:432px;min-height:432px;
            .pr-hot-spot { width:26px;height:26px; i, span {width:25px;height:26px;padding:4px;font-size:20px;}}
            .pr-hot-spot.prhs-01 {top:156px;left:163px;}
            .pr-hot-spot.prhs-02 {top:155px;left:295px;}
            .pr-hot-spot.prhs-03 {top:155px;left:344px;}
            .pr-hot-spot.prhs-04 {top: 90px;left:365px;}
            .pr-hot-spot.prhs-05 {top:191px;left:381px;}
            .pr-hot-spot.prhs-06 {top: 60px;left:600px;}
            .pr-hot-spot.prhs-07 {top:195px;left:600px;}
            .pr-hot-spot.prhs-08 {top:220px;left:660px;}
        } 
    } } 
    .pump-room.content-container { .pump-room-content.prc-two-col { 
        .columns {padding:{left:35px;right:35px;}margin:0;} .columns.pump-room-content-wrapper {padding:{left:0;right:0;}margin:0;} .pump-room-parts {padding:{left:35px;right:35px;} .prp-part {float:left;margin:0 10px 10px 0; h5 {display:inline-block;margin:0;} h5 a {margin-bottom:0;} .prpp-details {display:none;} } }
        .pump-room-interactive {background-image:url('/img/pump-room-0640.jpg');width:640px;max-width:640px;min-width:640px;height:360px;max-height:360px;min-height:360px;} 
            .pr-hot-spot { width:20px;height:20px; i, span {width:19px;height:20px;padding:4px;font-size:16px;}}
            .pr-hot-spot.prhs-01 {top:133px;left:140px;}
            /*.pr-hot-spot.prhs-02 {top:129px;left:247px;}*/
            .pr-hot-spot.prhs-02 {top:129px;left:345px;}
            .pr-hot-spot.prhs-03 {top:139px;left:289px;}
            .pr-hot-spot.prhs-04 {top: 79px;left:311px;}
            .pr-hot-spot.prhs-05 {top:155px;left:328px;}
            .pr-hot-spot.prhs-06 {top: 50px;left:500px;}
            .pr-hot-spot.prhs-07 {top:168px;left:500px;}
            .pr-hot-spot.prhs-08 {top:187px;left:555px;}
    } }
}
@media screen and (min-width:640px) and (max-width:1024px) {  
    .pump-room.content-container { 
        .main-content-container.columns {padding:{left:0px;right:0px;}}
        .main-content-container .main-content .page-information {padding:{left:20px;right:20px;}}
        .pump-room-content { .columns {padding:{left:35px;right:35px;}margin:0;} .columns.pump-room-content-wrapper {padding:{left:0;right:0;}margin:0;} .pump-room-parts {padding:{left:35px;right:35px;} .prp-part {float:left;margin:0 10px 10px 0; h5 {display:inline-block;margin:0;} h5 a {margin-bottom:0;} .prpp-details {display:none;} } }
} } }
@media screen and (min-width:640px) and (max-width:767px) {
     .pump-room.content-container { .pump-room-content, .pump-room-content.prc-full, .pump-room-content.prc-two-col { 
        .pump-room-interactive {background-image:url('/img/pump-room-0640.jpg');width:640px;max-width:640px;min-width:640px;height:360px;max-height:360px;min-height:360px;} 
            .pr-hot-spot { width:20px;height:20px; i, span {width:19px;height:20px;padding:4px;font-size:16px;}}
            .pr-hot-spot.prhs-01 {top:133px;left:140px;}
            /*.pr-hot-spot.prhs-02 {top:129px;left:247px;}*/
            .pr-hot-spot.prhs-02 {top:129px;left:345px;}
            .pr-hot-spot.prhs-03 {top:139px;left:289px;}
            .pr-hot-spot.prhs-04 {top: 79px;left:311px;}
            .pr-hot-spot.prhs-05 {top:155px;left:328px;}
            .pr-hot-spot.prhs-06 {top: 50px;left:500px;}
            .pr-hot-spot.prhs-07 {top:168px;left:500px;}
            .pr-hot-spot.prhs-08 {top:187px;left:555px;}
} } }
@media screen and (max-width:639px) {
     .pump-room.content-container {padding:{left:0;right:0;}margin:0;
        .main-content-container.columns {padding:{left:0;right:0;}margin:0;}
        .main-content-container {padding:{left:0;right:0;}margin:0;
            .page-information {padding:{left:20px;right:20px;}
                h1, h2 {text-align:center;}
            }
        }
        .pump-room-content, .pump-room-content.prc-full, .pump-room-content.prc-two-col {
            .columns {padding:{left:30px;right:30px;}}
            .columns.pump-room-content-wrapper {padding:{left:0;right:0;}margin:0;}
            .pump-room-content-wrapper { .pump-room-parts {
                    .prp-part {padding:25px 0 0 0;
                        h5 {text-align:center;margin-bottom:0 0 20px 0;}
                        a {margin:0;}
                        h5, h5 a, h5 a:link, h5 a:visited {background:transparent;padding:0;font-weight:400;font-size:25.00px;color:$clarke-dark-gray;text-transform:none;}
                        h5 a:hover, h5 a:active {background:transparent;color:$clarke-red;}
                        .prpp-details {display:block;text-align:center;
                            img {margin:0;padding:0;border:0 none;}
                            .prppd-content {padding:20px 30px;
                                p {font-weight:normal;color:$clarke-dark-gray;font-size:14.58px;padding:0;margin:0;}
                            }
                            a.prppd-button {display:inline-block;text-align:center;}
                        }
                    }
                }
            }
            .pump-room-interactive {display:none;}
        }    
    }
}
/*~^~.~^~.~^~*/

