﻿/* THIS SCSS FILE DEFINES THE CLARKE FIRE SITE */
@import "_variables.scss";


body {background:$clarke-gray;}

*:focus {outline:none 0 !important;}

h1 {font:{family:$font-stack-open-sans;size:37.5px;weight:300;style:normal;stretch:normal;}line-height:normal;letter-spacing:-1.30px;text-align:left;margin: 0   0 15px 0;color:$clarke-gray;}
h2 {font:{family:$font-stack-open-sans;size:33.3px;weight:400;style:normal;stretch:normal;}line-height:  40px;letter-spacing:-0.90px;text-align:left;margin:20px 0 15px 0;color:$clarke-gray;}
h3 {font:{family:$font-stack-open-sans;size:25.0px;weight:400;style:normal;stretch:normal;}line-height:normal;letter-spacing:-0.70px;text-align:left;margin: 0   0 15px 0;color:$clarke-gray;}
h4 {font:{family:$font-stack-open-sans;size:14.6px;weight:700;style:normal;stretch:normal;}line-height:normal;letter-spacing:-0.40px;text-align:left;margin: 0   0 15px 0;color:$clarke-black;text-transform:uppercase;}
h5 {font:{family:$font-stack-open-sans;size:12.5px;weight:700;style:normal;stretch:normal;}line-height:normal;letter-spacing:-0.40px;text-align:left;margin: 0   0 15px 0;color:$clarke-black;text-transform:uppercase;}
p  {font:{family:$font-stack-open-sans;size:16.0px;weight:400;style:normal;stretch:normal;}line-height:  1.29;letter-spacing:-0.40px;text-align:left;margin: 0   0 30px 0;color:$clarke-gray;}

a, a:link, a:visited  {color:$clarke-red;}
a:hover, a:active {color:$clarke-red-hover;}

img {border:0 none transparent;padding:0;margin:0;}

hr {height:2px;background-color:$clarke-gray-45;max-width:100%;margin:30px 0;}

.page-anchor::before {
    content:"";
    display:inline-block;
    height:100px;
    margin:-100px;
    z-index:0;
}

label {display:inline-block;font:{family:$font-stack-open-sans;size:14.58px;weight:700;}color:$clarke-gray-65;}
span.req {display:inline-block;padding-left:3px;font-family: "Open Sans",sans-serif;font-size:14.58px;font-weight:700;color:$clarke-red;}
label.selected, label.checked, label.active {color:$clarke-black;}

input[type="text"], input[type="email"], input[type="tel"], input[type="password"], input[type="date"], select, textarea {
    border-color:$clarke-gray-65;
    border-top-width:2px;
}

/* Foundation Sites is adding some really stupid overrides to the default select box presentation which it appears only Edge really supports. FF and Chrome use the default browser chrome regardless...except that chrome partially supports the change. */
/* Overridding it back to default.*/
select {
    background-image:none !important;
    /*background-size:initial !important;*/
    /*background-position:initial !important;*/
    /*background-origin:initial !important;*/
    /*-webkit-appearance:menulist !important;*//*webkit browsers */
    /*-moz-appearance:menulist !important;*//*Firefox */
    /*appearance:menulist !important;*//* modern browsers */
}

.dropdown-pane {width:auto;border-color:transparent;border-width:0;margin:0;padding:0;}

/*** "Responsive" Video Container ***/
.video-container {
	position:relative;
	padding-bottom:56.25%;
	padding-top:30px;
	height:0;
	overflow:hidden;

    iframe, object, embed {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
    }
}


/**** STANDARD BUTTONS ****/
/*** RED DEFAULT ***/
.primary-button {
    background: $clarke-red;
    color: $clarke-white;
    font:{family: $font-stack-open-sans; size: 12.5px; weight:400; style: normal; stretch: normal;}
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: left;
    text-transform: uppercase;
    display: inline-block;
    padding: 10px 15px 10px 15px;
    margin: 0 0 20px 0;
    transition: all 0.5s ease-in-out 0s;
    cursor:pointer;
    i {
        font:{size: 14.6px; weight: normal; style: normal; stretch: normal;}
        line-height: 1.29;
        letter-spacing: -0.4px;
        text-align: left;
        color: $clarke-white;
        margin-left:20px;
        margin-right:0px;
    }
    i.fai-left {
        margin-left:0px;
        margin-right:20px;
    }
}
.button-red {@extend .primary-button;}
a.primary-button, a.button-red, a.primary-button:link, a.button-red:link, a.primary-button:visited, a.button-red:visited {color:$clarke-white;}
.primary-button:hover, a.primary-button:hover, button.primary-button:hover,
.button-red:hover, a.button-red:hover, button.button-red:hover {background:$clarke-red-hover;color:$clarke-white;}
/*** DARK GRAY VARIANT ***/
.secondary-button {@extend .primary-button;background: $clarke-gray/*#555151;*/}
.button-dark-gray {@extend .primary-button;background: $clarke-gray/*#555151;*/}
.secondary-button:hover, a.secondary-button:hover, button.secondary-button:hover,
.button-dark-gray:hover, a.button-dark-gray:hover, button.button-dark-gray:hover {background:$clarke-gray-65;color:$clarke-white;}
/*** LIGHT GRAY VARIANT ***/
.tertiary-button {@extend .primary-button;background: $clarke-gray-65/*#b2b2b2;*/}
.button-light-gray {@extend .primary-button;background: $clarke-gray-65/*#b2b2b2;*/}
.tertiary-button:hover, a.tertiary-button:hover, button.tertiary-button:hover, 
.button-light-gray:hover, a.button-light-gray:hover, button.button-light-gray:hover {background:$clarke-gray-45;color:$clarke-white;}
/*** BACK TO TOP TAB BUTTON ***/
.button-page-top {
    background: $clarke-gray-65;
    color: $clarke-white;
    font:{family: $font-stack-open-sans; size: 21px; weight: 600; style: normal; stretch: normal;}
    line-height: normal;
    text-align: center;
    text-transform: uppercase;
    display: inline-block;
    padding: 2px 10px 2px 10px;
    margin:0;
    transition: all 0.5s ease-in-out 0s;
    cursor:pointer;
    i {
        font:{size: 21px; weight: 700; style: normal; stretch: normal;}
        text-align:center;
        color: $clarke-white;
        margin:0;
    }
}
.button-page-top:hover, .button-page-top:active {background-color:$clarke-gray-45;}

.search-button-gray {
    font-family: $font-stack-open-sans;
    font-size: 12.5px;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -0.3px;
    text-align: center;
    color: $clarke-white;
    background: $clarke-dark-gray;
    padding: 10px;
    margin: 0 auto;
    display: block;
    width: 100px;
}

/*** PRODUCT SEARCH ***/

.product-quick-search {
    .pqs-type, .pqs-emissions {
        label {width:100%;}
    }       

    .pqs-search-button.go-by-form {
        padding-top:10px;
    }
    
    .pqs-divider {
        width:100%;
        padding:10px 0 0 0;
        border-bottom:1px solid $clarke-black;
        margin:0 auto 10px auto;
    }

    .pqs-disclaimer {
        width:100%;
        padding:3px 0 0 0;
        p {
            font-size:12px;
        }
    }
}

/* Sliders */

.slider-container {
    .slider-label, .slider-min, .slider-input, .slider-max {display:block;position:relative;float:left;}
    .slider-label{
        font-size:14.58px;
        font-weight:700;
        text-align:left;
        padding:0 8px 0 0;
    }
    .slider-min {
        font-size:14.58px;
        font-weight:normal;
        text-align:right;
        padding:0 8px 0 0;
        min-width:45px;
    }
    .slider-input {
        min-width:100px;                
    }
    .slider-max {
        font-size:14.58px;
        font-weight:normal;
        text-align:left;
        padding:0 0 0 8px;
        min-width:45px;
    }
    .slider {
        height:1px;
        margin:10px 0;
        background-color:$clarke-black;
        .slider-fill {
            height:3px;
            background-color:$clarke-black;
        }
        .slider-handle {
            background:$clarke-gray-25;
            text-align:center;
            width:45px;
            height:24px;
            text-align:center;
            input[type="text"]{display:inline;height:auto;border:none 0 transparent;box-shadow:none;padding:0;margin:0;background:$clarke-gray-25;font:{family:$font-stack-open-sans;weight:500;size:14.58px;}color:$clarke-black;text-align:center;}
            i.glass-top {display:block;position:absolute;top:0;left:0;width:45px;height:23px;cursor:grab;}

            &.sh-left  {top:-12px;}
            &.sh-right {top: 14px;}
        }
    }
}

/**** UTILITY STYLES ****/
.p-0, .columns.p-0 {padding:0;}
.p-t-0, .columns.p-t-0 {padding-top:0;}
.p-r-0, .columns.p-r-0 {padding-right:0;}
.p-b-0, .columns.p-b-0 {padding-bottom:0;}
.p-l-0, .columns.p-l-0 {padding-left:0;}
.m-0, .columns.m-0 {margin:0;}
.m-t-0, .columns.m-t-0 {margin-top:0;}
.m-r-0, .columns.m-r-0 {margin-right:0;}
.m-b-0, .columns.m-b-0 {margin-bottom:0;}
.m-l-0, .columns.m-l-0 {margin-left:0;}
.m-c {margin:0 auto;}
.m-b-05 {margin-bottom:5px;}
.m-b-10 {margin-bottom:10px;}
.m-b-15 {margin-bottom:15px;}
.m-b-20 {margin-bottom:20px;}
.m-b-25 {margin-bottom:25px;}
.m-b-30 {margin-bottom:30px;}
.m-b-35 {margin-bottom:35px;}
.m-b-40 {margin-bottom:40px;}
.m-b-45 {margin-bottom:45px;}
.m-b-50 {margin-bottom:50px;}
.center-element {margin:0 auto;}
.clear {clear:both;float:none;}

/*~^~.~^~.~^~*/




/*************************/
/***** PAGE WRAPPER *****/
/************************/
.content-container {
    background: #efeceb;
}
/*~^~.~^~.~^~*/

/*****************************/
/***** MASTHEAD LAYOUTS *****/
/***************************/
/**** BASIC CONTENT MASTHEAD ****/
.masthead {
    background:{color:$clarke-gray;repeat:no-repeat;position-x:center;position-y:top;size:cover;}width:100%;margin:0 auto;border-bottom: 10px solid $clarke-black;
    h1 {
        display:inline-block;
        position:relative;
        white-space:nowrap;            
        margin:0;
        background: linear-gradient(rgba(0,0,0,.65), rgba(0,0,0,.65));
        color: $clarke-white;
        overflow:hidden;
    }
}
.masthead.columns {padding:{left:0;right:0;}}
/**** ROTATOR MASTHEADS ****/
.rotator-masthead {    
    border-bottom:10px solid $clarke-black;
    overflow:hidden;
    .orbit-container {top:-2px;
        .orbit-slide {height:460px; background-size:cover;
            .orbit-caption {
                background-color:transparent;
                position:relative;
                display:block;
                width:100%;
                height:460px;
                padding:67px 0 0 0;
                .caption-info {
                    position:relative;
                    display:inline-block;
                    width:auto;
                    background-color:rgba(10, 10, 10, 0.75);
                    padding:25px 50px;
                    h5 {color:$clarke-white;font-size:12.50px;font-weight:700;margin:0;}
                    h1 {color:$clarke-white;font-size:50.00px;font-weight:300;margin:0;line-height:60px;}
                }
                .caption-action {
                    padding:25px 50px 0 50px;
                }
            }
        }
    }
    .orbit-bullets {
        top: -70px;
        button {
            background-color:transparent;
            border-color:$clarke-white;border-width:1px;border-style:solid;
        }
        button.is-active {
            background-color:$clarke-white;
        }
    }
}
.rotator-masthead.news-masthead {
    height:460px;
}
.rotator-masthead.event-masthead {
    height:460px;
}
/**** RESPONSIVE OVERRIDES ****/
@media screen and (min-width:1921px) {
}
@media screen and (min-width:1440px) and (max-width:1920px) {
}
@media screen and (min-width:1081px) and (max-width:1439px) {
}
@media screen and (min-width:1025px) and (max-width:1080px) {
}
@media screen and (min-width:1024px){
    .masthead{height:180px;padding:85px 0 0 0;
        h1 {font-size:37.50px;padding:10px 50px;}
    }
    .masthead.large{height:450px;padding:0;
        h1 {font-size:37.50px;padding:10px 50px;}
    }
}
@media screen and (min-width:640px) and (max-width:1024px) {
    .masthead{height:130px;padding:50px 0 0 0;
        h1 {font-size:33.33px;padding:5px 30px;}
    }
    .rotator-masthead {           
        .orbit-container {
            .orbit-slide {height:300px;
                .orbit-caption {
                    height:300px;
                    padding:32px 0 0 0;
                    .caption-info {
                        padding:25px 30px;
                        h5 {font-size:12.50px;}
                        h1 {font-size:33.33px;line-height:40px;}
                    }
                    .caption-action {
                        padding:25px 50px 0 50px;
                    }
                }
            }
        }
    }
    .rotator-masthead.news-masthead {
        height:300px;
    }
    .rotator-masthead.event-masthead {
        height:300px;
    }
}
@media screen and (max-width:639px) {
    .masthead{height:142px;padding:58px 0 0 0;
        h1 {font-size:25.00px;padding:14px 28px;}
    }
    .rotator-masthead {           
        .orbit-container {
            .orbit-slide {height:360px;
                .orbit-caption {
                    height:360px;
                    padding:34px 0 0 0;
                    .caption-info {
                        padding:25px 30px;
                        h5 {font-size:12.50px;}
                        h1 {font-size:33.33px;line-height:40px;}
                    }
                    .caption-action {
                        padding:25px 50px 0 50px;
                    }
                }
            }
        }
    }
    .rotator-masthead.news-masthead {
        height:360px;
    }
    .rotator-masthead.event-masthead {
        height:360px;
    }
}
@media screen and (max-width:370px) {
}
/*~^~.~^~.~^~*/

/****************************/
/***** CONTENT SIDEBAR *****/
/**************************/
.content-container .content-sidebar-container {
    font-family: $font-stack-open-sans;
    float: left;

    .sidebar {
        margin: 60px 35px 0 35px;

        h4 {
            border-bottom: 2px solid #948f8f;
            padding-bottom: 10px;
            margin-bottom: 20px;
        }

        ul.primary {
            list-style-type: none;
            margin-left: 0;
        }

        a {
            font-size: 12.5px;
            text-transform: uppercase;
            color: $clarke-black;
        }

        .selected {
            font-weight: 700;
            color: $clarke-red;
        }

        i {
            margin-right: 5px;
        }

        ul.secondary {
            margin-left: 55px;
            list-style-type:none;

            li {
                text-indent: -5px;
            }

            a {
                text-transform: none;
            }
        }
    }
}

@media screen and (min-width:768px) {
    .content-container .content-sidebar-container { display:block; }
}
@media screen and (max-width:767px) {
    .content-container .content-sidebar-container { display:none; }
}
/*~^~.~^~.~^~*/

/*****************************************/
/***** MAIN CONTENT CONTAINER PANEL *****/
/***************************************/
.content-container .main-content-container {
    background:$clarke-canvas;
    float:right;

    .main-content {margin:0;padding:0;}

    .page-information {margin-top: 70px;
        .breadcrumbs {float:right;margin-top:15px;margin-bottom:0;text-align:right;
            span, a {color: $clarke-red;font:{family:$font-stack-open-sans;size:12.5px;weight:600;style:normal;stretch:normal;}line-height:normal;letter-spacing:-0.3px;text-align:left;text-transform:uppercase;}
            .active, .slash {color:$clarke-black;}
        }

        .page-title {float: left;
            h1 {color:$clarke-red; font-weight:500;font-size:33.33px;}
            h2 {color:$clarke-gray;font-weight:500;font-size:33.33px;margin-top: 0;}
        }                                                    
    }

    .top-content-container {
        margin-top: 30px;
        margin-bottom: 50px;

        p {
            font-family: $font-stack-open-sans;
            font-size: 14.6px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: -0.4px;
            text-align: left;
            color: #555151;
        }

        .top-section-image {
            /*margin-right: 4%;*/
            text-align: right;
        }
    }

    /*img {
        padding: 3px;
        border: 1px solid #9a9393;
    }

    .caption {
        font: {
            family: $font-stack-open-sans;
            size: 12.5px;
            weight: 300;
        }

        margin-top: 5px;

        .image-credit {
            font-weight: 500;
        }
    }*/

    .subsection {
        margin-bottom: 34px;

        h3 {
            font-family: $font-stack-open-sans;
            font-size: 25px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: -0.7px;
            text-align: left;
            color: #ee3425;
        }

        .subsection-information {
            margin-bottom: 13px;
        }

        .categories {
            font-size: 12.5px;
            font-family: $font-stack-open-sans;
            color: #555151;

            span {
                margin-right: 5px;
            }

            i {
                margin-right: 2px;
            }
        }

        .subsection-text {
        }

        .subsection-image {
            /*margin-right: 4%;*/
            text-align: right;
        }

        p {
            font-family: $font-stack-open-sans;
            font-size: 14.6px;
            font-weight: 300;
            font-style: normal;
            font-stretch: normal;
            line-height: 1.29;
            letter-spacing: -0.4px;
            text-align: left;
            color: #555151;

            a {
                font-weight: 500;
                color: $clarke-red;
            }
        }
    }

    .dealer-locator-iframe-wrapper {
        display:block;
        position:relative;
        width:100%;
        padding: 5px 15px 0;
        background-color:#FFF;
        border-radius: 10px;
    }
}
@media screen and (min-width:640px) and (max-width:767px) {
    .content-container .main-content-container.columns {width:100%;float:none;}
}

.video-modal {
    border-radius:10px;
    .vm-header {
        .vmh-title {
            &.columns {padding-left:0;}           
        }        
    }
    .vm-content {
        video.modal-video {
            width:100% !important;
        }
    }
    .vm-caption {
        &.columns {padding-left:0;}
    }
}

/*~^~.~^~.~^~*/

/****************************************/
/***** GENERIC CONTENT STYLE GUIDE *****/
/**************************************/
.generic-content {

    h1         {font:{family:$font-stack-open-sans;size:37.5px;weight:300;}color:   $clarke-gray;line-height:normal;letter-spacing:-1.3px;margin: 0   0 15px 0;}
    h2         {font:{family:$font-stack-open-sans;size:33.3px;weight:400;}color:   $clarke-gray;line-height:normal;letter-spacing:-0.9px;margin:20px 0 15px 0;}
    h3         {font:{family:$font-stack-open-sans;size:25.0px;weight:400;}color:   $clarke-gray;line-height:normal;letter-spacing:-0.7px;margin: 0   0 15px 0;font-style:normal;font-stretch:normal;}
    h4         {font:{family:$font-stack-open-sans;size:14.6px;weight:700;}color:   $clarke-gray;line-height:normal;letter-spacing:-0.4px;margin: 0   0 15px 0;text-transform:uppercase;}
    h5         {font:{family:$font-stack-open-sans;size:12.5px;weight:700;}color:   $clarke-gray;line-height:normal;letter-spacing:-0.4px;margin: 0   0 15px 0;text-transform:uppercase;}
    h6         {font:{family:$font-stack-open-sans;size:14.6px;weight:700;}color:   $clarke-gray;line-height:  1.29;letter-spacing:-0.4px;margin: 0   0 15px 0;font-style:normal;font-stretch:normal;}
    p          {font:{family:$font-stack-open-sans;size:14.6px;weight:400;}color:   $clarke-gray;line-height:  1.29;letter-spacing:-0.4px;margin: 0   0 30px 0;font-style:normal;font-stretch:normal;}
    blockquote {font:{family:$font-stack-open-sans;size:16.6px;weight:400;}color:$clarke-gray-65;line-height:normal;letter-spacing:-0.4px;margin: 0   0 30px 0;font-style:normal;font-stretch:normal;}
    li         {font:{family:$font-stack-open-sans;size:14.6px;weight:400;}color:   $clarke-gray;line-height:  1.29;letter-spacing:-0.4px;margin: 0   0 10px 0;font-style:normal;font-stretch:normal;}

    ul, ol {margin-bottom:20px;}
    li ul, li ol {margin-top:10px;}

    a {
        transition: all 0.5s ease-in-out 0s;
        &:link, &:visited {color:$clarke-red;text-decoration:none;}
        &:hover, &:active {color:$clarke-red-hover;text-decoration:underline;}
    }
    a.button-red, a.primary-button, a.button-dark-gray, a.button-secondary, a.button-light-gray, a.button-tertiary,
    a.button-red:link, a.primary-button:link, a.button-dark-gray:link, a.button-secondary:link, a.button-light-gray:link, a.button-tertiary:link,
    a.button-red:visited, a.primary-button:visited, a.button-dark-gray:visited, a.button-secondary:visited, a.button-light-gray:visited, a.button-tertiary:visited,
    a.button-red:hover, a.primary-button:hover, a.button-dark-gray:hover, a.button-secondary:hover, a.button-light-gray:hover, a.button-tertiary:hover,
    a.button-red:active, a.primary-button:active, a.button-dark-gray:active, a.button-secondary:active, a.button-light-gray:active, a.button-tertiary:active {
        color: $clarke-white;
        text-decoration: none;
    }

    img {border:0 none transparent;padding:0;}

    img, img.right {
        float: right;
        margin: 0 0px 15px 15px;
        display: inline;
        position: relative;
    }

    img.left {
        float: left;
        margin: 0 15px 15px 0px;
        display: inline;
        position: relative;
    }

    img.center {
        float: none;
        margin: 0 auto 15px auto;
        display: block;
        position: relative;
    }

    img.clean {
        float: none;
        margin: 0 0px 0px 0px;
        display: inline;
        position: relative;
    }

    .caption-image {
        display: inline-block;
        position: relative;
        float: right;
        width: auto;
        margin: 0 0 15px 15px;
        font-size: 12.50px;

        img {
            float: none;
            margin: 0 0px 0px 0px;
            display: inline;
            position: relative;
        }

        a.download-link {
            font-size: 12.50px;
            font-weight: 500;
            text-transform: uppercase;

            margin: {
                top: 10px;
            }
        }
    }

    hr {
        height: 2px;
        background-color: $clarke-black;
        max-width: 100%;
        margin: 30px 0;
    }
}



.data-grid {
    font: {
        family: $font-stack-open-sans;
        size: 14.58px;
        weight: 400;
    }

    color: $clarke-dark-gray;

    a, a:link, a:visited, a:hover, a:active {
        font-weight: 600;
    }

    .data-row {
        background-color: $clarke-canvas;
        padding: 7px 0;

        a, a:link, a:visited, a:hover, a:active {
            font-weight: 600;
        }
    }

    .data-row:nth-child(odd) {
        background-color: $clarke-gray-05;
    }
}

/* SF FORMS */
.sf-form-wrapper {
    padding-top:40px;
    .row {max-width:unset;}

    .sf-fieldWrp {padding-top:25px;}

    .form-group {
        .checkbox {
            label > [type="checkbox"] {
                display:block;
                float:left;
                margin-top:0.5rem;
            }
            label {font-weight:400;}
        }
        .radio {
            label {font-weight:400;}
        }

        /*SF Uses <STRONG> as a form group sub-heading */
        &[data-sf-role="multiple-choice-field-container"], &[data-sf-role="checkboxes-field-container"] {
            strong {color:$clarke-gray-65;font-size:14.58px;}
        }        
        
        &.primary-button {
            button, button.sf-SubmitButton, button.btn, button.btn-primary {                
                color:#FFF;
                font-family:$font-stack-open-sans;
                font-size:12.5px;
                font-weight:700;
                text-transform:uppercase;
                cursor:pointer;
            }
        }
    }


}

/*~^~.~^~.~^~*/


/***********************/
/***** LOGIN FORM *****/
/*********************/
.login-form {
    .lf-actions {
        .columns {
            padding: {
                left: 0;
                right: 0;
            }
        }

        .lfa-forgot {
            a {
                font-size: 14.58px;
            }
        }

        .lfa-submit {
            text-align: right;
        }
    }

    .lf-no-account {
        margin-top: 30px;

        a.button-red {
            margin-right: 35px;
        }
    }
}

@media screen and (min-width:1921px) {
    .login-form {
        max-width: 750px;
        margin: 0 auto;
    }
}

@media screen and (min-width:1025px) and (max-width:1920px) {
    .login-form {
        max-width: 550px;
    }
}

@media screen and (min-width:640px) and (max-width:1024px) {
    .login-form {
        max-width: 475px;
    }
}

@media screen and (max-width:639px) {
    .login-form {
        max-width: unset;
    }
}
/*~^~.~^~.~^~*/

/**** Register Now Modal ****/
.reveal.reg-now {
    padding:0;
    border:0 none;
    .rn-header {
        background:$clarke-gray;
        h4 {color:$clarke-white;padding:13px 0;margin:0;}
        button.close-button {color:$clarke-white;font-size:1.75rem;font-weight:bold;transition: all 0.5s ease-in-out 0s;}
        button.close-button:hover {color:$clarke-red;cursor:pointer;}
    }
    .rn-content {
        padding:10px 0;
        h5 {color:$clarke-red;}
    }
    .rn-actions {
        .rna-options {}
        .rna-cancel {
            text-align:right;
        }
    }
}

/**************************/
/***** PARTS CONTENT *****/
/************************/
 .content-container .main-content-container {
    .related-parts {
        .topics {
            background-image: url('/img/oil-filter-small.png');
        }

        .topic-title-container {
            margin-top: 10px;
            background: transparent;
            width: auto;
            height: auto;
        }

        .topic-title {
            font-family: $font-stack-open-sans;
            font-size: 17px;
            letter-spacing: -0.4px;
            color: #37424a;
            padding: 0;

            span {
                font-family: $font-stack-open-sans;
                font-size: 13px;
                line-height: 1.62;
                letter-spacing: -0.2px;
                color: #333337;
            }
        }
    }

    .parts-details-container {
        margin-top: 25px;
        margin-bottom: 50px;

        span {
            font-family: $font-stack-open-sans;
            font-size: 15px;
            line-height: 1.4;
            letter-spacing: -0.2px;
            color: #333337;
        }

        .purchase {
            margin-top: 30px;

            fieldset {
                padding-left: 0;
            }

            .secondary-button {
                margin-top: 20px;
            }

            select {
                background-image: none;
            }
        }
    }

    .part-compatibility-container {
        margin-bottom: 50px;

        span {
            font-family: $font-stack-open-sans;
            font-size: 13px;
            line-height: 3.23;
            letter-spacing: -0.2px;
            color: #333337;
        }

        thead tr {
            background-color: #818a8f;
            font-family: $font-stack-open-sans;
            font-size: 15px;                    
            line-height: 2.8;
            letter-spacing: -0.2px;                    
            color: $clarke-white;
        }
    }

}
/*~^~.~^~.~^~*/


/******************************/
/***** SITEFINITY SEARCH *****/
/****************************/
.content-container { 
    .search-information {
        margin-bottom: 15px;
    }

    .number-results {
        font: {
            family: $font-stack-open-sans;
            size: 16px;
        }

        color: #3e3e3e;
    }

    .search-result-container {
        border-bottom: 1px solid #B2B2B2;
        padding-bottom: 25px;
        padding-left: 0;
        margin-top: 25px;

        .search-category {
            font-family: $font-stack-open-sans;
            font-size: 12px;
            font-weight: bold;
            line-height: 0;
            color: #2F2F2F;
            text-transform: uppercase;
        }

        .search-title {
            color: #EE372A;
            margin-top: 12px;
            margin-bottom: 12px;
        }

        .search-description {
            font-size: 16px;
            margin-bottom: 0;

            a {
                color: $clarke-red;
                font-weight: bold;
            }
        }
    }

    .pagination {
        margin-top: 50px;
        margin-bottom: 50px;
        padding-left: 0;

        .number-results {
            display: inline-block;
        }

        .list-of-pages {
            display: inline-block;

            span.k-state-selected {
                padding-top:0;
                margin:{top:7px;left:7px;right:7px;}
                border-top:2px solid $clarke-red;
                color: $clarke-red;
                font-family: $font-stack-open-sans;
                font-size: 18px;
                font-weight: bold;
            }
            a.k-link {
                font-family: $font-stack-open-sans;
                font-size: 18px;
                transition: all 0.5s ease-in-out 0s;
                &:link, &:visited {
                    color: $clarke-black;
                }
                &:hover, &active {
                    color: $clarke-red;
                }
            }
            

            .active {
                color: $clarke-black;
            }
        }
    }
}
.k-ff .k-pager-numbers + .k-link, .k-ie11 .k-pager-numbers + .k-link, .k-safari .k-pager-numbers + .k-link, .k-webkit .k-pager-numbers + .k-link {
    margin-left:1rem !important;
}

.serp-wrapper {
    .serp-heading {

    }
    .serp-languages {

    }
    .serp-list {
        margin-top:15px;
        border-top:1px solid $clarke-gray;
        padding-top:15px;
        .serp-list-item {
            padding-bottom:25px;
            .serp-img-thumb {

            }
            .serp-vid-thumb {

            }
            .serp-item-title {
                h3 {
                    margin-bottom:5px;
                    a {                    
                        &:link, &:visited {color:$clarke-gray;text-decoration:none;}
                        &:hover, &:active {color:$clarke-red;text-decoration:none;}
                    }
                }
            }
            .serp-item-link {
                a {
                    &:link, &:visited {color:$clarke-red;text-decoration:none;}
                    &:hover, &:active {color:$clarke-red;text-decoration:underline;}
                }
            }   
        }
    }
}

/*~^~.~^~.~^~*/

/******************************/
/***** SITEFINITY EVENTS *****/
/****************************/
/**** EVENTS LISTING ****/
.content-container .event-list {
    margin-left: 15px;
    margin-bottom: 30px;

    h3 {
        margin-bottom: 20px;
        margin-top: 30px;
    }

    .event-item {
        margin-bottom: 30px;
        overflow: auto;
    }

    .date {
        display: block;
        position: relative;
        float: left;
        width: 98px;
        background: #54514F;
        padding: 25px 20px;
        margin: 0 20px 0 0;
        font-size: 50px;
        font-weight: 300;
        color: $clarke-white;
        line-height: 30px;
        text-align: center;
    }

    .event-info {
        display: block;
        position: relative;
        float: left;
        width: 70%;
    }

    .event-title {
        font-family: $font-stack-open-sans;
        font-size: 20px;
        color: $clarke-red;
        margin-bottom: 10px;
    }

    p, li {
        font-family: $font-stack-open-sans;
        font-size: 16px;
        font-weight: 300;
        color: #222223;
        margin-bottom: 5px;
        line-height: 1;
    }
}
/**** EVENT DETAILS PAGE ****/
.content-container .event-details-container {
    /*display: inline-block;*/
    /*margin-left: 15px;*/
    display:block;
    padding-top:25px;
    h3 {margin-bottom:30px;}
    .event-details {
        .event-grouping {
            padding:{bottom:23px;}
            h4 {font-size:15.75px;text-transform:none;margin-bottom:10px;color:$clarke-dark-gray;}
            h5 {font-size:16.67px;text-transform:none;margin-bottom: 0;  color:$clarke-red;}
            p  {font-size:16.67px;text-transform:none;margin-bottom: 0;  color:$clarke-dark-gray;}
            .sf-add-to-calendar {
                ul {list-style-type:none;margin:{left:0;}}
                li {font-size:16.67px;font-weight:600;color:$clarke-red;text-transform:none;margin:{bottom:5px;}
                    i, span {color:$clarke-black;padding-right:5px;}
                }
            }
            .sf-registration {
                p {margin-bottom:10px;}
                ul {color:$clarke-red;margin-bottom:15px;}
                li {font-size:16.67px;font-weight:600;color:$clarke-red;text-transform:none;margin:{bottom:5px;}}
            }
        }
    }
    .event-details-left {
        float: left;
        padding-left: 0;

        /*.event-label {
            font-family: $font-stack-open-sans;
            font-size: 18.8px;
            letter-spacing: -0.4px;
            color: #1a1a1a;
        }

        p {
            font-family: $font-stack-open-sans;
            font-size: 16.7px;
            font-weight: 500;
            color: #222223;
        }

        p span {
            color: #ee3425;
        }*/
    }

    .event-details-right {
        float: right;

        /*img {
            margin-top: 45px;
        }

        a {
            text-transform: uppercase;
            font-family: $font-stack-open-sans;
            font-size: 10px;
            font-weight: bold;
        }*/
    }
}
/*~^~.~^~.~^~*/

/****************************/
/***** SITEFINITY NEWS *****/
/**************************/
/**** NEWS LISTING ****/
.content-container {
    .news-title {
        h3 {
            margin-bottom: 0;
        }

        h5 {
            margin-bottom: 5px;
        }
    }
    .news-list {
        .news-list-category {
            h3 {
                color:$clarke-red;font-size:33.33px;font-weight:500;
            }
        }
        .news-item {
            padding-bottom:20px;
            .ni-date {
               h6 {
                   color:$clarke-dark-gray;font-size:12.50px;font-weight:700;text-transform:uppercase;margin:{bottom:0;}
               }
            }
            .ni-content {
                h4 {
                    color:$clarke-red;font-size:25px;font-weight:400;margin:{bottom:0;}text-transform:none;
                }
                p {
                    color:$clarke-dark-gray;font-size:16.67px;font-weight:400;
                    a {font-weight:500;}
                }
            }
            .ni-thumbnail {text-align:right;
                img {width:100%;max-width:400px;}
            }
        }
    }
}
/**** NEWS DETAILS PAGE ****/
.content-container  .news-details-container {
    .news-title {
        margin-bottom:30px;
        h3 {
            margin-bottom:15px;
        }
        h6 {
            font-size:12.50px;font-weight:600;text-transform:uppercase;
        }
    }
}
/*~^~.~^~.~^~*/

/*****************************/
/***** SITEFINITY LISTS *****/
/***************************/
.content-container { 

    .list.page-title {
        margin-bottom: 45px;
    }

    .list-details {
        margin-top: 45px;
    }

    .list-accordion {
        i {
            margin-right: 5px;
        }

        p {
            margin-top: 15px;
            margin-bottom: 15px;
        }

        ul {
            list-style-type: none;
        }
    }

    .list-qa {
        margin-bottom: 45px;

        h3 {
            margin-bottom: 25px;
        }

        h5 {
            color: $clarke-red;
        }
    }
}
/*~^~.~^~.~^~*/

/**********************************/
/***** RELATED TOPICS WIDGET *****/
/********************************/
.content-container .main-content-container .related-topic-container {
    margin-bottom: 60px;
    h3 {font-size:33.33px;}
    .topic {display:block;position:relative;float:left;width:173px;height:115px;background:{color:$clarke-gray;repeat:no-repeat;size:cover;}margin:0 20px 20px 0;
        a {display:block;position:relative;width:173px;height:115px;
            span {display:block;position:relative;top:82px;width:169px;padding:6px 10px;margin:0 auto;background-color:$clarke-red;color:$clarke-white;font-size:12px;overflow:hidden;
            }
        }
    }
}
@media screen and (max-width:639px) {
    .content-container .main-content-container .related-topic-container {
        margin-bottom:20px;
        .topic {float:none;margin:0 auto 20px auto;}
    }
}
/*~^~.~^~.~^~*/

/**********************************/
/***** CALL TO ACTION BANNER *****/
/********************************/
.call-to-action-container {
    background-color:$clarke-black;
    border-top: 10px solid $clarke-black;
    .cta-inner {
        /*background-image: linear-gradient(to right,rgba(0,0,0,0), rgba(0,0,0,1)60%), url('/img/call-to-action.jpg');*/
        background:{color:$clarke-black;repeat:no-repeat;size:cover;}
        height:auto;
        padding:40px 40px 0;

        max-width:1920px;
        margin:0 auto;

        overflow:hidden;
        div {display:block;position:relative;}
        .cta-headline {text-align:center;margin:0 auto;color:$clarke-white;
            h1, h2, h3, h4, h5, h6 {display:inline;font:{family:$font-stack-open-sans;weight:400;size:33.33px;}color:$clarke-white;line-height:40px;margin:0;text-transform:none;}
        }
        .cta-content {float:left;text-align:right;color:$clarke-white;padding-top:8px;
            h6, p {display:inline;font:{family:$font-stack-open-sans;weight:400;size:14.57px;}color:$clarke-white;margin:0;}
        }
        .cta-action {float:right;text-align:left;}
    }
}
@media screen and (min-width:1921px) {
    .call-to-action-container .cta-inner {padding:40px;
        .cta-headline {width:34%;padding-bottom:30px;
            h1, h2, h3, h4, h5, h6 {font-size:41.48px;line-height:50px;}
        }
        .cta-content {width:49%;
            h6, p {font-size:18.15px;}
        }
        .cta-action {width:49%;}
    }
}
@media screen and (min-width:1024px) and (max-width:1920px){
    .call-to-action-container .cta-inner {padding:40px;
        .cta-headline {width:50%;padding:{bottom:25px;}
        }
        .cta-content {width:48%;}
        .cta-action {width:48%;}
    }
}
@media screen and (min-width:640px) and (max-width:1024px) {
    .call-to-action-container .cta-inner {padding:40px;
        .cta-headline {width:75%;padding:{bottom:25px;}}
        .cta-content {width:47%;}
        .cta-action {width:47%;}
    }
}
@media screen and (max-width:639px) {
    .call-to-action-container .cta-inner {padding:40px;
        .cta-headline {width:100%;padding:{bottom:25px;}
            h1, h2, h3, h4, h5, h6 {font-size:25.00px;letter-spacing:-1.2px;line-height:30px;}            
        }
        .cta-content {float:none;width:100%;text-align:center;padding:{top:0;bottom:30px;}}
        .cta-action {float:none;width:100%;text-align:center;}
    }
}
@media screen and (max-width:370px) {
    .call-to-action-container .cta-inner {height:auto;padding:40px;}
}
/*~^~.~^~.~^~*/


/*******************/
/***** FOOTER *****/
/*****************/
footer, .footer {
    background: #4C4848 none no-repeat center top;
    border-top: 10px solid $clarke-black;
    padding:60px 0 0 0;
    .footer-mega-menu { .fmm-menu {float:left;width:20%;margin:0;padding:0;} }
    h4, h4 a {font:{family: $font-stack-montserrat;size: 14.6px;}letter-spacing:-0.3px;text-align:left;color:$clarke-white;text-transform:uppercase;transition: all 0.5s ease-in-out 0s;
        :hover, :active {color:$clarke-red;text-decoration:none;}
        :visited {color:$clarke-white;text-decoration:none;}
    }
    ul {margin-left:12px;}
    li, li a {font:{family: $font-stack-montserrat;size: 12.5px;}line-height:2;letter-spacing:-0.3px;text-align:left;color:$clarke-white;transition:all 0.5s ease-in-out 0s;
        :hover, :active {color:$clarke-red;text-decoration:none;}
        :visited {color:$clarke-white;text-decoration:none;}
    }
    .footer-main {padding:30px 0;
        .footer-main-inner {text-align:center;
            a {display:inline-block;margin:0 30px;font:{family:$font-stack-montserrat;size:10.4px;weight:700;}letter-spacing:-0.2px;text-align:left;color:$clarke-white;text-transform:uppercase;transition:all 0.5s ease-in-out 0s;
                :hover, :active {color:$clarke-red;text-decoration:none;}
                :visited {color:$clarke-white;text-decoration:none;}
    } } }
}
.footer-container {background:$clarke-gray;border-top: 10px solid $clarke-black;

    /*xfooter {background-image: url('/img/footer.jpg');background: $clarke-gray none no-repeat center top;height:auto;
        h4 {margin-top: 45px; a {font:{family: $font-stack-montserrat;size:14.6px;}letter-spacing:-0.3px;text-align:left;color:$clarke-white;text-transform:uppercase;}}
        .footer-secondary {color:$clarke-white;margin-left:15px;
            li {text-indent: -5px;}
            a {font:{family: $font-stack-montserrat;size: 12.5px;}line-height:2;letter-spacing:-0.3px;text-align:left;color:$clarke-white;}
        }
        .footer-utility-bar {float:none !important;margin-left:33%;margin-top:3%;
            li {display: inline-block;margin-right: 55px;}
            a {font:{family:$font-stack-montserrat;size:10.4px;}letter-spacing:-0.2px;text-align:left;color:$clarke-white;text-transform:uppercase;}
        }
    }*/
}
.copyright-container {background-color:$clarke-red;height:30px;text-align:center;padding:3px 0 0 0;
    span, a {display:inline-block;position:relative;letter-spacing:-0.2px;text-align:left;color:$clarke-white;text-transform:uppercase;}
    .copyright, a {font:{family:$font-stack-montserrat;size:10.2px;weight:bold;}}
    .copyright {padding: 0 5px 0 0;}
    span.fa {font-size:8px;padding:0 20px 0 20px;top:-2px;}
}
/*~^~.~^~.~^~*/

   

    /*RESPONSIVE OVERRIDES*/


    @media screen and (min-width:2049px) {
        footer, .footer {height:auto;max-height:320px;background:$clarke-gray url('/img/gray-footer-wide.jpg') no-repeat center top;
            .footer-mega-menu {
                padding:0 100px;
            }
        }
    }

    @media screen and (min-width:1600px) and (max-width:2048px) {
        footer, .footer {height:auto;max-height:320px;background:$clarke-gray url('/img/gray-footer-2048.jpg') no-repeat left top;
            .footer-mega-menu {
                padding:0 100px;
            }
        }
    }
        
    @media screen and (min-width:1201px) and (max-width:1599px) {
        footer, .footer {height:auto;max-height:320px;background:$clarke-gray url('/img/gray-footer-1600.jpg') no-repeat left top;
            .footer-mega-menu {
                padding:0 100px;
            }
        }
    }

    /*Full Width Screens (Greater than Design Desktop Breakpoint) */
    @media screen and (min-width:1201px)
    {
        
    }

    
    /*Intermediate between Design Desktop and Foundation Large*/
    @media screen and (min-width:1025px) and (max-width:1200px)
    {
        footer, .footer {height:auto;max-height:320px;background:$clarke-gray url('/img/gray-footer-1200.jpg') no-repeat left top;padding:60px 40px 0;
            .footer-mega-menu {
                padding:0;
            }
        }
    }

    /*Foundation Large*/
    @media screen and (min-width:1024px)
    {
        .main-content-container.columns {margin:0;padding:0 40px 25px;}
        .content-container .stepper {height:90px;}
        .content-container .stepper .step-visual-indicator {text-align:right;}
        .step-visual-indicator .primary-button, .step-visual-indicator .secondary-button, .step-visual-indicator .tertiary-button {margin-left:5px;margin-right:0px;}
        .reg-form .reg-left.columns {padding-left:0;}
        .reg-form .reg-right.columns {padding-right:0;}
        .reg-support {display:none;}
        .rcc-support {display:block;}
    }

    @media screen and (min-width:1001px) and (max-width:1023px)
    {
        footer, .footer {height:auto;max-height:247px;background:$clarke-gray url('/img/gray-footer-1024.jpg') no-repeat center top;padding:60px 40px 0;
            .footer-mega-menu {
                padding:0;
                .fmm-menu, .fmm-menu h4, .fmm-menu h4 a {
                    text-align:center;
                }
                .mf-submenu {display:none;}
            }
        }
    }

    @media screen and (min-width:769px) and (max-width:1000px)
    {
        footer, .footer {height:auto;max-height:247px;background:$clarke-gray url('/img/gray-footer-1000.jpg') no-repeat left top;padding:60px 40px 0;
            .footer-mega-menu {
                padding:0;
                .fmm-menu, .fmm-menu h4, .fmm-menu h4 a {
                    text-align:center;
                }
                .mf-submenu {display:none;}
            }
        }
    }

    /*Intermediate between Design Tablet and Foundation Large*/
    @media screen and (min-width:769px) and (max-width:1023px)
    {
        /*footer {
            height:auto;max-height:247px;background-image:url('/img/footer-1023.jpg');      
            .footer-mega-menu {
                .fmm-menu, .fmm-menu h4, .fmm-menu h4 a {
                    text-align:center;
                }
            }
        }*/
    }

    /*Foundation Medium*/
    @media screen and (min-width:640px) and (max-width:1023px)
    {
        .content-container {
            .main-content-container {
                .page-information {margin-top:40px;                
                    .breadcrumbs {float:none;width:100%;text-align: right;}
                    .page-title {float:none;width:100%;}
                }
            }
            .main-content-container.columns {margin:0;padding:0 20px 25px;}
            .stepper {margin-top:10px;
                .step-visual-indicator {text-align:right;
                    .primary-button, .secondary-button, .tertiary-button {margin-left:5px;margin-right:0;}
                }
            }
        }

        .reg-support {display:none;}
        .rcc-support {display:block;}

        /*footer, .footer {
            height:auto;max-height:247px;background-image:url('/img/footer-1023.jpg');      
            .footer-mega-menu {
                padding:0;
                .fmm-menu, .fmm-menu h4, .fmm-menu h4 a {
                    text-align:center;
                }
                .mf-submenu {display:none;}
            }
        }*/
    }

    /*Intermediate between Design Tablet Breakpoint and Foundation Medium*/
    @media screen and (min-width:640px) and (max-width:768px)
    {
        footer, .footer {height:auto;max-height:247px;background:$clarke-gray url('/img/gray-footer-0768.jpg') no-repeat left top;
            .footer-mega-menu {
                padding:0;
                .fmm-menu, .fmm-menu h4, .fmm-menu h4 a {
                    text-align:center;
                }
                .mf-submenu {display:none;}
            }
        }
    }
    
    @media screen and (max-width:770px) {
        .columns.reg-ctl-right {float:none;text-align:center;width:100%;}
        .columns.reg-ctl-left {float:none;text-align:center;width:100%;}
    }

    /*Foundation Small*/
    @media screen and (max-width:639px)
    {
        .content-container {
            .main-content-container {
                .page-information {margin-top:40px;
                    .breadcrumbs {margin-bottom:15px;}
                }
            }
            .main-content-container.columns {margin:0;padding:0 20px 25px;}
            .stepper {
                .step-visual-indicator {text-align:left;
                    .primary-button, .secondary-button, .tertiary-button {margin-left:0;margin-right:5px;}
                }
                .columns.step-visual-indicator {float:none;}
                .columns.step-title {float:none;}
            }
        }
        .columns.reg-ctl-right {float:none;text-align:center;}
        .columns.reg-ctl-left {float:none;text-align:center;}

        .reg-support {display:block;}
        .rcc-support {display:none;}

        footer, .footer {
            height:auto;max-height:unset;padding:0;
            .footer-mega-menu {
                padding:0;
                .fmm-menu {
                    float:none;
                    width:100%;
                    text-align:center;
                    background-color:$clarke-dark-gray; /*#302C2B;*/
                    border-bottom:1px solid $clarke-gray-65; /*#827F7F;*/
                    h4, h4 a {
                        color:$clarke-gray-25;
                        text-align:center;
                        margin:0px;
                        padding:15px 0;
                        transition: all 0.5s ease-in-out 0s;
                        :hover, :active {
                            color:$clarke-red;text-decoration:none;
                        }
                        :visited {
                            color:$clarke-gray-25;text-decoration:none;
                        }                        
                    }
                    :last-child {border-bottom:0px none transparent;}
                }                
                .mf-submenu {display:none;}
            }
            .footer-main {
                padding:40px 0;
                .footer-main-inner {                
                    a {                    
                        margin:0 10px;                    
                    }
                }
            }
        }

        footer .footer-mega-menu .fmm-menu:last-child, .footer .footer-mega-menu .fmm-menu:last-child {border-bottom:0px none transparent;}

        .copyright-container { 
            height:100px; 
            padding:20px 0 0 0;
            .copyright {
                display:block;
                width:100%;
                padding:0;
                text-align:center;
            }
            span.fa.first {display:none;}
        }        
    }

    /*Intermediate between Design Mobile and Foundation Small*/
    @media screen and (min-width:513px) and (max-width:639px)
    {
        footer, .footer {
            .footer-main {
                height:103px;
                background: $clarke-gray url('/img/gray-footer-0640.jpg') no-repeat center top;
            }
        }
    }
    @media screen and (min-width:376px) and (max-width:512px)
    {
        footer, .footer {
            .footer-main {
                height:103px;
                background: $clarke-gray url('/img/gray-footer-0512.jpg') no-repeat left top;
            }
        }
    }

    /*Design Mobile Breakpoint*/
    @media screen and (max-width:375px)
    {        
        footer, .footer {
            .footer-main {
                height:103px;
                background: $clarke-gray url('/img/gray-footer-0375.jpg') no-repeat left top;
            }
        }
    }


    /*** PRINT OVERRIDES ***/
    @media print {
        div.divHideOnPrint {display:none !important;}
        .page-break {page-break-after:always;}
        div.utility-bar-container {display:none;}
        div.sticky-container {display:none;}
        div.red-header-container {
            display:block !important;
            border:none 0 transparent;
            div.red-header { 
                border:none 0 transparent;
                border-bottom:solid 2px #000;
                div.logo.columns {width:100% !important;float:none;border:none 0 transparent;}
                section.actions.columns {display:none;}
            }
            div.mobile-dropdowns {display:none;}
        }
        div.main-content-container.columns {width:100% !important;float:none;}
        aside.content-sidebar-container {display:none;}
        footer {display:none !important;
            &.footer {display:none !important;}
        }
        div.copyright-container {
            span {display:none;}
            a {display:none;}
            span.copyright {display:block;}
        }

        /* The below prevents URLs from hyperlinks from displaying when printing (a feature provided by Zurb Foundation) - WI 500872 / Trackit 57001 - 2018-06-26 - DSSmith*/
        a[href]:after {
          display: none;
          visibility: hidden;
        }
    }